import { FEEDBACK_SUCCESS_MESSAGE } from '../types';

const initialState = {
  success: false,
  feedback: null,
};

const feedbackReducer = function (state = initialState, action: any) {
  switch (action.type) {
    case FEEDBACK_SUCCESS_MESSAGE: {
      return {
        ...state,
        success: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default feedbackReducer;
