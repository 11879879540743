const translations = {
  // Tab Bar
  'more_film.components.bottomNav.home': 'Home',
  'more_film.components.bottomNav.newproject': 'Anfrage',
  'more_film.components.bottomNav.projects': 'Projekte',
  'more_film.components.bottomNav.contacts': 'Kontakte',
  'more_film.components.bottomNav.feedback': 'Feedback',

  // Header
  'more_film.component.header.back': 'Zurück',

  // Header Slim
  'more_film.component.headerSlim.back': 'Zurück',
  'more_film.component.headerSlim.cancel': 'Abbrechen',

  // Login / Signup
  'more_film.containers.login.email': 'Emailadresse',
  'more_film.containers.login.phone': 'Telefon',
  'more_film.containers.login.password': 'Passwort',
  'more_film.containers.login.login': 'Anmelden',
  'more_film.containers.login.create': 'Neues Konto erstellen',
  'more_film.containers.login.or': 'oder',
  'more_film.containers.login.forgotPassword': 'Passwort vergessen?',
  'more_film.containers.login.imprintText': 'Impressum',
  'more_film.containers.login.termsText': 'Datenschutz',
  'more_film.containers.login.fieldRequired': 'Dieses Feld ist erforderlich.',
  'more_film.containers.login.registerCompleteHeading':
    'Registrierung erfolgreich!',
  'more_film.containers.login.registerCompleteMessage':
    'Die Registierung war erfolgreich. Sie werden automatisch angemeldet!',
  'more_film.containers.login.registerCompleteAction': 'Anmelden',
  'more_film.containers.login.loginError': 'Anmeldung fehlgeschlagen!',
  'more_film.containers.login.registerErrorAction': 'Schließen',
  'more_film.containers.login.registerErrorMessage':
    'Die Registrierung konnte nicht abgeschlossen werden',
  'more_film.containers.login.invalidEmail':
    'Bitte geben Sie eine gültige E-Mail Adresse ein.',
  'more_film.containers.login.invalidPhone':
    'Bitte geben Sie eine gültige Telefonnummer ein.',
  'more_film.containers.login.resetPassword': 'Passwort zurücksetzen',
  'more_film.containers.login.resetMailSent':
    'Eine E-Mail mit den nächsten Schritten wurde verschickt.',
  'more_film.containers.login.resetError':
    'Diese E-Mail Adresse existiert eventuell nicht im System!',

  // Feedback
  'more_film.containers.feedback.title': 'Feedback',
  'more_film.containers.feedback.rate': 'Wie bewerten Sie unsere Firma?',
  'more_film.containers.feedback.dreadful': 'Schrecklich',
  'more_film.containers.feedback.poor': 'Schlecht',
  'more_film.containers.feedback.average': 'Durchschnittlich',
  'more_film.containers.feedback.fine': 'Gut',
  'more_film.containers.feedback.awesome': 'Super',

  'more_film.containers.feedback.praise': 'Lob',
  'more_film.containers.feedback.complaint': 'Kritik',
  'more_film.containers.feedback.suggestion': 'Vorschlag',

  'more_film.containers.feedback.feedbackTypeMsg': 'Feedback-Art auswählen',
  'more_film.containers.feedback.feedbackTypePlaceholder': 'Auswahl...',
  'more_film.containers.feedback.feedbackOkText': 'Auswählen',
  'more_film.containers.feedback.feedbackCancelText': 'Abbrechen',

  'more_film.containers.feedback.addComment': 'Kommentar hinzfügen',
  'more_film.containers.feedback.commentMsg': 'Schreiben Sie ein paar Sätze',
  'more_film.containers.feedback.feedbackTypeHead':
    'Können Sie dazu noch mehr sagen?',
  'more_film.containers.feedback.sendFeedback': 'Feedback senden',
  'more_film.containers.feedback.fieldIsRequired': 'Feld ist erforderlich',
  'more_film.containers.feedback.alertHeader': 'Danke für Ihre Zeit',
  'more_film.containers.feedback.alertMessage':
    'Leider können wir nicht auf jedes Feedback antworten. Ihre Meinung wird uns jedoch helfen, unsere Firma für Sie und andere zu verbessern',
  'more_film.containers.feedback.errorMessage':
    'Bitte versuchen Sie es später erneut.',
  'more_film.containers.feedback.errorHeader': 'Etwas ist schief gegangen!',
  // Home
  'more_film.containers.home.title': 'Home',
  'more_film.containers.home.item1': 'Neues Projekt',
  'more_film.containers.home.item2': 'Projekte',
  'more_film.containers.home.item3': 'Kontakt',
  'more_film.containers.home.item4': 'Feedback',
  'more_film.containers.home.item5': 'Version',
  'more_film.containers.home.customers': 'Kunden',

  // Contacts
  'more_film.containers.contact.title': 'Kontakt',
  'more_film.containers.contact.subHeading': 'Ihre direkten Ansprechpartner',

  // Projects List
  'more_film.containers.projects.title': 'Projekte',
  'more_film.containers.projects.searchBar': 'Nach einem Projekt suchen',
  'more_film.containers.projects.noDateGiven': 'Kein Datum angegeben',
  'more_film.containers.projects.brainstorming': 'Im Brainstorming',
  'more_film.containers.projects.noOffer': 'Kein Angebot verfügbar',
  'more_film.containers.projects.offerAvailable': 'Neues Angebot',
  'more_film.containers.projects.preproduction': 'In Vorproduktion',
  'more_film.containers.projects.production': 'In Produktion',
  'more_film.containers.projects.postproduction': 'In Postproduktion',
  'more_film.containers.projects.projectFinished': 'Abgeschlossen',
  'more_film.containers.projects.element': '{amount} Element',
  'more_film.containers.projects.elements': '{amount} Elemente',

  // Profile
  'more_film.containers.profile.title': 'Profil bearbeiten',
  'more_film.containers.profile.cancel': 'Abbrechen',
  'more_film.containers.profile.save': 'Speichern',
  'more_film.containers.profile.avatar': 'Fügen Sie hier Ihr Profilbild hinzu',
  'more_film.containers.profile.firstName': 'Vorname',
  'more_film.containers.profile.lastName': 'Nachname ',
  'more_film.containers.profile.address': 'Adresse',
  'more_film.containers.profile.country': 'Land',
  'more_film.containers.profile.email': 'E-Mail',
  'more_film.containers.profile.position': 'Position',
  'more_film.containers.profile.phone': 'Telefon',
  'more_film.containers.profile.mobile': 'Mobiltelefon',
  'more_film.containers.profile.website': 'Webseite',
  'more_film.containers.profile.company': 'Unternehmen',
  'more_film.containers.profile.logout': 'Logout',
  'more_film.containers.profile.noCompaniesAssigned':
    'Aktuell keiner Firma zugeteilt!',
  'more_film.containers.profile.alertHeader': 'Erfolg',
  'more_film.containers.profile.alertMessage':
    'Profil erfolgreich aktualisiert!',
  'more_film.containers.profile.fieldRequired': 'Dieses Feld ist erforderlich.',
  'more_film.containers.profile.invalidEmail':
    'Bitte geben Sie eine gültige E-Mail Adresse ein.',
  'more_film.containers.profile.invalidPhone':
    'Bitte geben Sie eine gültige Telefonnummer ein.',
  'more_film.containers.profile.alertErrorHeader':
    'Ein Fehler ist aufgetreten!',
  'more_film.containers.profile.alertErrorMessage':
    'Ihr Profil konnte nicht aktualisiert werden! Bitte probieren Sie es später erneut.',
  'more_film.containers.profile.closeAction': 'Schließen',
  'more_film.containers.profile.secretLabel': 'Einlade-Code',
  'more_film.containers.profile.shareAction': 'Teilen',
  'more_film.containers.profile.copyToClipboardAction': 'Kopieren',
  'more_film.containers.profile.removePhoto': 'Bild löschen',
  'more_film.containers.profile.photoRemoved':
    'Bild wird nach dem Speichern gelöscht.',

  'more_film.containers.profile.cameraUnavailable':
    'Sie haben in den Systemeinstellungen den Kamerazugriff deaktiviert.',
  'more_film.containers.profile.libraryUnavailable':
    'Sie haben in den Systemeinstellungen den Zugriff auf Ihre Fotos deaktiviert.',
  'more_film.containers.profile.unableToLoadImage':
    'Es gab einen Fehler beim Laden des Bildes.',
  'more_film.containers.profile.deleteUser': 'Sie wollen Ihren Account dauerhaft löschen?',
  'more_film.containers.profile.deleteUserInitAction': '<u>Tippe hier,</u> um den Account zu löschen.',
  'more_film.containers.profile.deleteUserHeader': 'Sind Sie sicher?',
  'more_film.containers.profile.deleteUserMessage': 'Die Lösung ist permanent und kann nicht zurückgenommen werden! Alle Ihre Inhalte werden gelöscht!',
  'more_film.containers.profile.deleteUserAction': 'Account löschen!',
  'more_film.containers.profile.deleteUserErrorDismissAction': 'Okay',
  'more_film.containers.profile.deleteUserError': 'Es gab einen Fehler. Bitte kontaktieren Sie den Support!',
  'more_film.containers.profile.clearAppCache': 'Leere App Cache',
  'more_film.containers.profile.appCacheCleared': 'App Cache wurde geleert!',
  'more_film.containers.profile.business': 'Unternehmensverwaltung',

  // Project request
  'more_film.containers.nProject.title': 'Neues Projekt',
  'more_film.component.timeline.nProject.cardBrainstorming.head':
    'Brainstorming',
  'more_film.component.timeline.nProject.cardBrainstorming.description':
    'Beschreibe dein geplantes Vorhaben und erhalte ein unverbindliches Angebot',
  'more_film.component.timeline.nProject.cardBrainstorming.button':
    'Projektbeschreibung',
  'more_film.component.timeline.nProject.cardBrainstorming.footer': 'Beratung',
  'more_film.component.timeline.nProject.cardOffer.head': 'Angebot',
  'more_film.component.timeline.nProject.cardOffer.description':
    'Überprüfen Sie das unverbindliche Angebot.',
  'more_film.component.timeline.nProject.cardOffer.button':
    'Angebot überprüfen',
  'more_film.component.timeline.nProject.cardOffer.footer': 'Beratung',
  'more_film.component.timeline.nProject.cardOffer.acceptedDescription':
    'Das Angebot wurde bereits von Ihnen akzeptiert. Hier sehen sie die Angebotsdokumente.',
  'more_film.component.timeline.nProject.cardOffer.acceptedButton':
    'Originales Angebot ansehen',

  'more_film.component.timeline.nProject.card3.head': 'Vorproduktion',
  'more_film.component.timeline.nProject.card3.description':
    'Sprechertexte, Locationscouting, Teamplanung, Equipmentplaung, Konzept...',
  'more_film.component.timeline.nProject.card3.button': 'Details',

  'more_film.component.timeline.nProject.card3.review':
    `Wir hoffen das Ergebnis ist zufriedenstellend.

    Jetzt eine Google-Bewertung für Roll it Films hinterlassen!
    Damit unterstützen Sie uns bei unserem Anspruch, Roll it Films stetig zu verbessern.`,
  'more_film.component.timeline.nProject.card3.link': 'Jetzt bewerten',

  'more_film.component.timeline.nProject.card4.head': 'Produktion',
  'more_film.component.timeline.nProject.card5.head': 'Postproduktion',
  'more_film.component.timeline.nProject.card6.head': 'Fertig',
  'more_film.component.timeline.nProject.videoTitle': 'Informationen',
  'more_film.component.timeline.nProject.close': 'Schließen',
  'more_film.component.timeline.nProject.mediaNotFound':
    'Video konnte nicht gefunden werden',

  // Brainstorming
  'more_film.containers.nProject.brainstorming.title': 'Brainstorming',
  'more_film.containers.nProject.brainstorming.newProjectSubtitle':
    'Neues Projekt erstellen',
  'more_film.containers.nProject.brainstorming.newProjectTitle':
    'Projekt Information',
  'more_film.containers.nProject.brainstorming.nameLabel': 'Projektname',
  'more_film.containers.nProject.brainstorming.nameRequiredError':
    'Das Projekt braucht einen Namen!',
  'more_film.containers.nProject.brainstorming.brainstormingPlaceholder':
    'Beschreibe dein geplantes Vorhaben und erhalte ein unverbindliches Angebot.',
  'more_film.containers.nProject.brainstorming.brainstormingRequiredError':
    'Für das Projekt benötigt Ihr ein Brainstorming.',
  'more_film.containers.nProject.brainstorming.errorHeading':
    'Das Projekt konnte nicht erstellt werden',
  'more_film.containers.nProject.brainstorming.genericErrorMessage':
    'Ein unerwarteter Fehler ist aufgetraucht.',
  'more_film.containers.nProject.brainstorming.actionSubmit':
    'Projekt beantragen',

  // Offer
  'more_film.containers.projects.offer.title': 'Angebot',
  'more_film.containers.projects.offer.offerStatusOpen': 'Ausstehend',
  'more_film.containers.projects.offer.offerStatusAccepted': 'Angenommen',
  'more_film.containers.projects.offer.noOfferHeader':
    'Aktuell kein Angebot verfügbar.',
  'more_film.containers.projects.offer.noOfferMessage':
    'Ein Angebot wird noch ausgearbeitet.',
  'more_film.containers.projects.offer.actionGoBack': 'Zurück',
  'more_film.containers.projects.offer.offerHeader': 'Angebot',
  'more_film.containers.projects.offer.viewOfferDocumentAction': 'Ansehen',
  'more_film.containers.projects.offer.openSignatureModalAction':
    'Angebot annehmen',
  'more_film.containers.projects.offer.closeDocumentModalAction': 'Schließen',
  'more_film.containers.projects.offer.showSignatureButton':
    'Signatur anzeigen',
  'more_film.containers.projects.offer.attachedDocumentHeader':
    'Angehangenes Dokument',

  'more_film.containers.projects.signOffer.errorModalHeader':
    'Etwas ist schief gegangen!',
  'more_film.containers.projects.signOffer.errorModalMessage':
    'Es gab einen unerwarteten Fehler!<br />Das Angebot wurde nicht akzeptiert.',
  'more_film.containers.projects.signOffer.errorModalCloseAction': 'Schließen',
  'more_film.containers.projects.signOffer.signature': 'Unterschrift',
  'more_film.containers.projects.signOffer.date': 'Datum',
  'more_film.containers.projects.signOffer.title': 'Angebot unterschreiben',
  'more_film.containers.projects.signOffer.acceptOfferAction':
    'Angebot annehmen',
  'more_film.containers.projects.signOffer.clearSignatureAction': 'Leeren',
  'more_film.containers.projects.signOffer.closeAction': 'Schließen',

  // Project details
  'more_film.containers.projectDetail.title': 'Projekt',
  'more_film.containers.projectDetail.errorTitle': 'Oh oh!',
  'more_film.containers.projectDetail.errorHeading': 'Etwas ging schief!',
  'more_film.containers.projectDetail.errorUnknown':
    'Es gab einen unerwarteten Fehler!',
  'more_film.containers.projectDetail.errorResourceNotFound':
    'Das Projekt konnte nicht gefunden werden oder Informationen fehlen!',
  'more_film.containers.projectDetail.head1': 'Projektname',
  'more_film.containers.projectDetail.head2': 'Projektleiter',
  'more_film.containers.projectDetail.head3': 'Projektabschluss',
  'more_film.containers.projectDetail.head4': 'Projektnummer',
  'more_film.containers.projectDetail.action1': 'Speichern', //in fotoalbum speichern
  'more_film.containers.projectDetail.action2': 'Teilen', //teilen
  'more_film.containers.projectDetail.action3': 'Korrektur (kostenpflichtig)', //korrectur
  'more_film.containers.projectDetail.action4': 'Lob / Kritik (Bewertung) ', //lob/kritik
  'more_film.containers.projectDetail.action5': 'Nachrichten',
  'more_film.containers.projectDetail.action6': 'Email',
  'more_film.containers.projectDetail.action7': 'Link kopieren',
  'more_film.containers.projectDetail.action8': 'Teilen mit...',
  'more_film.containers.projectDetail.actionGoBack': 'Zurück',
  'more_film.containers.projectDetail.detailsTab': 'Fortschritt',
  'more_film.containers.projectDetail.mediaTab': 'Medien',
  'more_film.containers.projectDetail.downloading': 'Bereite alles vor...',
  'more_film.containers.projectDetail.savedAsset': 'Datei gespeichert!',
  'more_film.containers.projectDetail.qualityHeader': 'Download Qualität auswählen:',
  'more_film.containers.projectDetail.shareAssetText':
    'Neuer Review Link von Frame.io.',
  'more_film.containers.projectDetail.errorSharingAsset':
    'Datei konnte nicht geteilt werden!',
  'more_film.containers.projectDetail.errorSavingAsset':
    'Es gab einen Fehler beim Herunterladen!',
  'more_film.containers.projectDetail.clickToEnhance':
    'Antippen zum vergrößern',
  'more_film.containers.projectDetail.mediaNotFound':
    'Die Datei konnte nicht geladen werden!',
  'more_film.containers.projectDetail.documentsTab': 'Dokumente',
  'more_film.containers.projectDetail.version': "Version:",

  // Project Documents
  'more_film.containers.projects.documents.closeDocumentModalAction': 'Schließen',
  'more_film.containers.projects.documents.noDocumentsAvaible': 'Keine Dokumente vorhanden',
  'more_film.containers.projects.documents.uploadDocuments': 'Dokument(e) hochladen',
  'more_film.containers.projects.documents.addDocuments': 'Dateien hochladen',
  'more_film.containers.projects.documents.dropFiles': 'Dateien hier ablegen/einfügen oder',
  'more_film.containers.projects.documents.browseFiles': 'Datei auswählen',

  // Video Correction Player
  'more_film.containers.videoPlayer.title': 'Videokorrektur',
  'more_film.containers.videoPlayer.comments': 'Kommentare',
  'more_film.containers.videoPlayer.noComments':
    'Machen Sie den Anfang und senden Sie Feedback!',
  'more_film.containers.videoPlayer.info': 'Dateiinfo',
  'more_film.containers.videoPlayer.addComments': 'Kommentar hinzufügen...',
  'more_film.containers.videoPlayer.cancelAction': 'Abbrechen',
  'more_film.containers.videoPlayer.sendComment': 'Abschicken',
  'more_film.containers.videoPlayer.noFileInformation':
    'Keine Informationen verfügbar',

  // Project Specific Timeline
  'more_film.containers.projectTimeline.title': 'Projekt',

  'more_film.containers.create_company.startHeader': 'Herzlich Willkommen!',
  'more_film.containers.create_company.startMessage': 'Es kann losgehen!',
  'more_film.containers.create_company.joinAction':
    'Ich wurde eingeladen, indem mir ein Company Code mitgeteilt wurde.',
  'more_film.containers.create_company.createAction':
    'Ich möchte eine neue Firma anlegen.',
  'more_film.containers.create_company.joinToolbarHeader': 'Beitreten',
  'more_film.containers.create_company.joinHeader': 'Herzlich Willkommen!',
  'more_film.containers.create_company.joinMessage':
    'Wo möchtest du beitreten?',
  'more_film.containers.create_company.joinCodePlaceholder': 'Code',
  'more_film.containers.create_company.joinByCodeAction': 'Beitreten',
  'more_film.containers.create_company.createToolbarHeader':
    'Neue Organisation anlegen',
  'more_film.containers.create_company.createHeader': 'Herzlich Willkommen!',
  'more_film.containers.create_company.createMessage':
    'Eine Organisation ist das Dach für alle deine Projekte bei More. Eine Organisation ist beispielsweise eine Firma, ein Verein oder du als Privatperson.',
  'more_film.containers.create_company.createQuestion':
    'Wie möchtest du deine Organisation nennnen?',
  'more_film.containers.create_company.createCompanyPlaceholder':
    'Organisationsname',
  'more_film.containers.create_company.createCompanyAction':
    'Organisation anlegen',
  'more_film.containers.create_company.closeErrorAction': 'Schließen',
  'more_film.containers.create_company.errorHeader':
    'Etwas ist schief gegangen!',
  'more_film.containers.create_company.errorMessage':
    'Sie konnten dem Unternehmen nicht beitreten. Bitte überprüfen Sie, ob der Code korrekt ist oder versuchen Sie es später noch einmal.',

  'more_film.components.production_field.closeDocumentModal': 'Schließen',
  'more_film.components.production_field.imageGallery': 'Bildergalerie',
  'more_film.components.production_field.attachedDocumentHeader': 'Datei',
  'more_film.components.production_field.viewDocumentAction': 'Ansehen',
  'more_film.components.production_field.contentLabel': 'Inhalt',
  'more_film.components.production_field.filesHeader': 'Dateien',
  'more_film.components.production_field.errorPopoverHeader': 'Fehler',
  'more_film.components.production_field.errorPopoverMessage':
    'Etwas ist schief gegangen!',
  'more_film.components.production_field.errorPopoverConsequence':
    'Das Feld konnte nicht aktualisiert werden.',
  'more_film.components.production_field.errorPopoverRetryAction':
    'Nochmal aktualisieren',
  'more_film.components.production_field.errorPopoverCloseAction': 'Schließen',
  'more_film.components.production_field.uploadSuccessMessage':
    'Datei "{file}" erfolgreich hochgeladen',
  'more_film.components.production_field.uploadSuccessDismissAction':
    'Schließen',
  'more_film.components.production_field.uploadUnsuccessfulHeader':
    'Hochladen nicht erfolgreich',
  'more_film.components.production_field.uploadUnsuccessfulMessage':
    'Die Datei "{file}" konnte nicht erfolgreich hochgeladen werden. Nochmal versuchen?',
  'more_film.components.production_field.uploadUnsuccessfulDoNotingAction':
    'Nein',
  'more_film.components.production_field.uploadUnsuccessfulRetryAction':
    'Nochmal',
  'more_film.components.production_field.noFilesAvailable':
    'Es sind keine Dateien vorhanden.',

  'more_film.components.upload_file.uploadAction': 'Hochladen',

  'more_film.containers.projects.production-step.preproduction':
    'Vorproduktion',
  'more_film.containers.projects.production-step.production': 'Produktion',
  'more_film.containers.projects.production-step.postproduction':
    'Postproduktion',
  'more_film.containers.projects.production-step.noFieldsAvailableHeader':
    'Inhalte ausstehend.',
  'more_film.containers.projects.production-step.noFieldsAvailableMessage':
    'Bitte schauen Sie später vorbei.',
  'more_film.containers.projects.production-step.goBackAction':
    'Zurück zur Übersicht.',

  'more_film.containers.videoPlayer.versionSelector.cancelAction': 'Abbrechen',
  'more_film.containers.videoPlayer.comments.completed': 'Abgeschlossen!',

  // 404
  'more_film.containers.not_found.header': '😮',
  'more_film.containers.not_found.title':
    'Das was du suchst, konnte nicht gefunden werden.',
  'more_film.containers.not_found.link': 'Zurück',

  // Password Reset - Set Password
  'more_film.containers.reset_password.header': 'Neues Passwort',
  'more_film.containers.reset_password.passwordLabel': 'Neues Passwort',
  'more_film.containers.reset_password.resetAction': 'Passwort aktualisieren',
  'more_film.containers.reset_password.fieldRequired':
    'Das Feld darf nicht leer sein!',
  'more_film.containers.reset_password.fieldMinLength':
    'Das neue Passwort muss mindestens 12 Zeichen lang sein!',
  'more_film.containers.reset_password.successHeader': 'Fertig',
  'more_film.containers.reset_password.successMessage':
    'Das Passwort wurde aktualisiert! Sie werden nun zum Login weitergeleitet.',
  'more_film.containers.reset_password.successButton': 'Zum Login',
  'more_film.containers.reset_password.errorHeader': 'Fehler',
  'more_film.containers.reset_password.errorMessage':
    'Es gab einen Fehler... Sie werden nun zurück zur Passwort-Zurücksetzen-Seite geleitet.',
  'more_film.containers.reset_password.errorButton': 'Weiter',

  'more_film.containers.videoPlayer.selectColorHeader': 'Farbe auswählen',
  'more_film.containers.videoPlayer.errorHeader': 'Fehler',
  'more_film.containers.videoPlayer.errorMessage':
    'Der Kommentar konnte nicht gespeichert werden!',
  'more_film.containers.videoPlayer.errorButton': 'Ok',
  'more_film.containers.videoPlayer.colors.white': 'Weiß',
  'more_film.containers.videoPlayer.colors.black': 'Schwarz',
  'more_film.containers.videoPlayer.colors.red': 'Rot',
  'more_film.containers.videoPlayer.colors.green': 'Grün',
  'more_film.containers.videoPlayer.colors.blue': 'Blau',
  'more_film.containers.videoPlayer.colors.cyan': 'Cyan',
  'more_film.containers.videoPlayer.colors.magenta': 'Magenta',
  'more_film.containers.videoPlayer.colors.yellow': 'Gelb',
  'more_film.containers.videoPlayer.colors.orange': 'Orange',
  'more_film.containers.videoPlayer.colors.purple': 'Lila',

  // Image Gallery
  'more_film.containers.imageGallery.title': 'Bildergalerie',

  'more_film.hooks.camera.promptLabelCancel': 'Abbrechen',
  'more_film.hooks.camera.promptLabelHeader': 'Neues Profilbild auswählen',
  'more_film.hooks.camera.promptLabelPicture': 'Kamera',
  'more_film.hooks.camera.promptLabelPhoto': 'Galerie',

  'more_film.components.error.title': 'Es gab einen kritischen Fehler!',
  'more_film.components.error.info':
    'Die App ist abgestürzt und konnte den Fehler nicht abfangen. Der Fehler:',
  'more_film.components.error.reset': 'App neustarten',

  'more_film.containers.customers.title': 'Kunden',
  'more_film.containers.customers.searchBar': 'Suche nach Kunden',
  'more_film.containers.projectDetail.mediaNotAvailable': 'Medien nicht verfügbar!',
};

export default translations;
