import { User } from '../../model';
import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const userEndpoint = 'users/me';
export const postUser = async (token: string, user: Partial<User>) =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${userEndpoint}${getDevelopQueryParam()}`,
    {
      headers: getHeaders(token),
      method: 'POST',
      body: JSON.stringify(user),
    },
  );

export const useUpdateUser = () => {
  return useMutation(
    ({ token, user }: { token: string; user: Partial<User> }) => {
      return postUser(token, user);
    },
  );
};
