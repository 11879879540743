// Stores the currently-being-typechecked object for error messages.
import { Staff } from './staff';

let obj: any = null;
export class StaffProxy implements Staff {
  public readonly id: number;
  public readonly name: string;
  public readonly slug: string;
  public readonly phone: string | null;
  public readonly position: string | null;
  public readonly email: string | null;
  public readonly image?: string | false;
  public static Parse(d: string): StaffProxy {
    return StaffProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): StaffProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkNumber(d.id, false, field + '.id');
    checkString(d.name, false, field + '.name');
    checkString(d.slug, false, field + '.slug');
    checkString(d.phone, true, field + '.phone');
    if (d.phone === undefined) {
      d.phone = null;
    }
    checkString(d.position, true, field + '.position');
    if (d.position === undefined) {
      d.position = null;
    }
    checkString(d.email, true, field + '.email');
    if (d.email === undefined) {
      d.email = null;
    }
    // This will be refactored in the next release.
    try {
      checkString(d.image, false, field + '.image');
    } catch (e) {
      try {
        checkBoolean(d.image, false, field + '.image');
      } catch (e) {
        throw e;
      }
    }
    if (d.image === undefined) {
      d.image = null;
    }
    return new StaffProxy(d);
  }
  private constructor(d: any) {
    this.id = d.id;
    this.name = d.name;
    this.slug = d.slug;
    this.phone = d.phone;
    this.position = d.position;
    this.email = d.email;
    this.image = d.image;
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, 'non-nullable object', false);
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable);
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable);
}
function checkNumber(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'number' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'number', nullable);
  }
}
function checkBoolean(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'boolean' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'boolean', nullable);
  }
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'string' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'string', nullable);
  }
}
function errorHelper(
  field: string,
  d: any,
  type: string,
  nullable: boolean,
): never {
  if (nullable) {
    type += ', null, or undefined';
  }
  throw new TypeError(
    'Expected ' +
      type +
      ' at ' +
      field +
      ' but found:\n' +
      JSON.stringify(d) +
      '\n\nFull object:\n' +
      JSON.stringify(obj),
  );
}
