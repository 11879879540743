// Stores the currently-being-typechecked object for error messages.
import { StaffProxy } from './staff.proxy';
import { CompanyProxy } from './company.proxy';
import {
  FieldsEntity,
  FilesEntity,
  FrameioMediaEntity,
  ProductionEntity,
  Project,
  ProjectProgress,
  QuotationDocument,
} from './project';

let obj: any = null;

export class ProjectProxy implements Project {
  public readonly id: number;
  public readonly title: string;
  public readonly slug: string;
  public readonly completion: string | null;
  public readonly staff_manager?: StaffProxy | null;
  public readonly frameio_project: string | null;
  public readonly company: CompanyProxy;
  public readonly project_progress: ProjectProgressProxy;
  public readonly frameio_media: FrameioMediaEntityProxy[] | null;
  public readonly documents: FilesEntityProxy[] | null;

  private constructor(d: any) {
    this.id = d.id;
    this.title = d.title;
    this.slug = d.slug;
    this.completion = d.completion;
    this.staff_manager = d.staff_manager;
    this.frameio_project = d.frameio_project;
    this.company = d.company;
    this.project_progress = d.project_progress;
    this.frameio_media = d.frameio_media;
    this.documents = d.documents
  }

  public static Parse(d: string): ProjectProxy {
    return ProjectProxy.Create(JSON.parse(d));
  }

  public static Create(d: any, field: string = 'root'): ProjectProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkNumber(d.id, false, field + '.id');
    checkString(d.title, false, field + '.title');
    checkString(d.slug, false, field + '.slug');
    checkString(d.completion, true, field + '.completion');
    if (d.completion === undefined) {
      d.completion = null;
    }
    try {
      d.staff_manager = StaffProxy.Create(
        d.staff_manager,
        field + '.staff_manager',
      );
    } catch (e: any) {
      d.staff_manager = undefined;
    }
    if (d.staff_manager === undefined) {
      d.staff_manager = null;
    }
    checkString(d.frameio_project, true, field + '.frameio_project');
    if (d.frameio_project === undefined) {
      d.frameio_project = null;
    }
    d.company = CompanyProxy.Create(d.company, field + '.company');
    d.project_progress = ProjectProgressProxy.Create(
      d.project_progress,
      field + '.project_progress',
    );
    checkArray(d.frameio_media, field + '.frameio_media');
    if (d.frameio_media) {
      for (let i = 0; i < d.frameio_media.length; i++) {
        d.frameio_media[i] = FrameioMediaEntityProxy.Create(
          d.frameio_media[i],
          field + '.frameio_media' + '[' + i + ']',
        );
      }
    }
    if (d.frameio_media === undefined) {
      d.frameio_media = null;
    }

    checkArray(d.documents, field + '.documents');
    if (d.documents) {
      for (let i = 0; i < d.documents.length; i++) {
        d.documents[i] = FilesEntityProxy.Create(
          d.documents[i],
          field + '.documents' + '[' + i + ']',
        );
      }
    }

    return new ProjectProxy(d);
  }
}

export class ProjectProgressProxy implements ProjectProgress {
  public readonly brainstorming: string | null;
  public readonly brainstorming_document: QuotationDocumentProxy;
  public readonly quotation_document: QuotationDocumentProxy;
  public readonly quotation_accepted: boolean;
  public readonly signature_url: string;
  public readonly preproduction: ProductionEntityProxy;
  public readonly production: ProductionEntityProxy;
  public readonly postproduction: ProductionEntityProxy;

  private constructor(d: any) {
    this.brainstorming = d.brainstorming;
    this.brainstorming_document = d.brainstorming_document;
    this.quotation_document = d.quotation_document;
    this.quotation_accepted = d.quotation_accepted;
    this.signature_url = d.signature_url;
    this.preproduction = d.preproduction;
    this.production = d.production;
    this.postproduction = d.postproduction;
  }

  public static Parse(d: string): ProjectProgressProxy {
    return ProjectProgressProxy.Create(JSON.parse(d));
  }

  public static Create(d: any, field: string = 'root'): ProjectProgressProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkString(d.brainstorming, true, field + '.brainstorming');
    if (d.brainstorming === undefined) {
      d.brainstorming = null;
    }
    d.brainstorming_document = QuotationDocumentProxy.Create(
      d.brainstorming_document,
      field + '.brainstorming_document',
    );
    d.quotation_document = QuotationDocumentProxy.Create(
      d.quotation_document,
      field + '.quotation_document',
    );
    checkBoolean(d.quotation_accepted, false, field + '.quotation_accepted');
    d.preproduction = ProductionEntityProxy.Create(
      d.preproduction,
      field + '.preproduction',
    );
    d.production = ProductionEntityProxy.Create(
      d.production,
      field + '.production',
    );
    d.postproduction = ProductionEntityProxy.Create(
      d.postproduction,
      field + '.postproduction',
    );
    return new ProjectProgressProxy(d);
  }
}

export class QuotationDocumentProxy implements QuotationDocument {
  public readonly id: number | null;
  public readonly slug: string | null;
  public readonly name: string | null;
  public readonly excerpt: string | null;
  public readonly content: string | null;
  public readonly url: string | false;
  public readonly thumbnail: string | false;
  public readonly mime_type: string | null;

  private constructor(d: any) {
    this.id = d.id;
    this.slug = d.slug;
    this.name = d.name;
    this.excerpt = d.excerpt;
    this.content = d.content;
    this.url = d.url;
    this.thumbnail = d.thumbnail;
    this.mime_type = d.mime_type;
  }

  public static Parse(d: string): QuotationDocumentProxy {
    return QuotationDocumentProxy.Create(JSON.parse(d));
  }

  public static Create(d: any, field: string = 'root'): QuotationDocumentProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkNumber(d.id, true, field + '.id');
    if (d.id === undefined) {
      d.id = null;
    }
    checkString(d.slug, true, field + '.slug');
    if (d.slug === undefined) {
      d.slug = null;
    }
    checkString(d.name, true, field + '.name');
    if (d.name === undefined) {
      d.name = null;
    }
    checkString(d.excerpt, true, field + '.excerpt');
    if (d.excerpt === undefined) {
      d.excerpt = null;
    }
    checkString(d.content, true, field + '.content');
    if (d.content === undefined) {
      d.content = null;
    }
    // This will be refactored in the next release.
    try {
      checkString(d.url, false, field + '.url');
    } catch (e) {
      try {
        checkBoolean(d.url, false, field + '.url');
      } catch (e) {
        throw e;
      }
    }
    checkString(d.mime_type, true, field + '.mime_type');
    if (d.mime_type === undefined) {
      d.mime_type = null;
    }
    return new QuotationDocumentProxy(d);
  }
}

export class ProductionEntityProxy implements ProductionEntity {
  public readonly completed: boolean;
  public readonly fields: (FieldsEntityProxy | null)[] | null;

  private constructor(d: any) {
    this.completed = d.completed;
    this.fields = d.fields;
  }

  public static Parse(d: string): ProductionEntityProxy {
    return ProductionEntityProxy.Create(JSON.parse(d));
  }

  public static Create(d: any, field: string = 'root'): ProductionEntityProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkBoolean(d.completed, false, field + '.completed');
    checkArray(d.fields, field + '.fields');
    if (d.fields) {
      for (let i = 0; i < d.fields.length; i++) {
        d.fields[i] = FieldsEntityProxy.Create(
          d.fields[i],
          field + '.fields' + '[' + i + ']',
        );
        if (d.fields[i] === undefined) {
          d.fields[i] = null;
        }
      }
    }
    if (d.fields === undefined) {
      d.fields = null;
    }
    return new ProductionEntityProxy(d);
  }
}

export class FieldsEntityProxy implements FieldsEntity {
  public readonly uuid: string;
  public readonly title: string;
  public readonly content: string;
  public readonly content_editable: boolean;
  public readonly files: FilesEntityProxy[] | null;
  public readonly files_editable: boolean;

  private constructor(d: any) {
    this.uuid = d.uuid;
    this.title = d.title;
    this.content = d.content;
    this.content_editable = d.content_editable;
    this.files = d.files;
    this.files_editable = d.files_editable;
  }

  public static Parse(d: string): FieldsEntityProxy | null {
    return FieldsEntityProxy.Create(JSON.parse(d));
  }

  public static Create(
    d: any,
    field: string = 'root',
  ): FieldsEntityProxy | null {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      return null;
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, true);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, true);
    }
    checkString(d.uuid, false, field + '.uuid');
    checkString(d.title, false, field + '.title');
    checkString(d.content, false, field + '.content');
    checkBoolean(d.content_editable, false, field + '.content_editable');
    checkArray(d.files, field + '.files');
    if (d.files) {
      for (let i = 0; i < d.files.length; i++) {
        d.files[i] = FilesEntityProxy.Create(
          d.files[i],
          field + '.files' + '[' + i + ']',
        );
      }
    }
    if (d.files === undefined) {
      d.files = null;
    }
    checkBoolean(d.files_editable, false, field + '.files_editable');
    return new FieldsEntityProxy(d);
  }
}

export class FilesEntityProxy implements FilesEntity {
  public readonly id: number | null;
  public readonly slug: string | null;
  public readonly name: string | null;
  public readonly excerpt: string | null;
  public readonly content: string | null;
  public readonly url: string | false;
  public readonly mime_type: string | null;
  public readonly file_size: number | false | null;
  public readonly date: Date | false | null;

  private constructor(d: any) {
    this.id = d.id;
    this.slug = d.slug;
    this.name = d.name;
    this.excerpt = d.excerpt;
    this.content = d.content;
    this.url = d.url;
    this.mime_type = d.mime_type;
    this.file_size = d.file_size;
    this.date = d.date;
  }

  public static Parse(d: string): FilesEntityProxy {
    return FilesEntityProxy.Create(JSON.parse(d));
  }

  public static Create(d: any, field: string = 'root'): FilesEntityProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkNumber(d.id, true, field + '.id');
    if (d.id === undefined) {
      d.id = null;
    }
    checkString(d.slug, true, field + '.slug');
    if (d.slug === undefined) {
      d.slug = null;
    }
    checkString(d.name, true, field + '.name');
    if (d.name === undefined) {
      d.name = null;
    }
    checkString(d.excerpt, true, field + '.excerpt');
    if (d.excerpt === undefined) {
      d.excerpt = null;
    }
    checkString(d.content, true, field + '.content');
    if (d.content === undefined) {
      d.content = null;
    }
    // This will be refactored in the next release.
    try {
      checkString(d.url, false, field + '.url');
    } catch (e) {
      try {
        checkBoolean(d.url, false, field + '.url');
      } catch (e) {
        try {
          // This will be refactored in the next release.
          try {
            checkBoolean(d.url, false, field + '.url');
          } catch (e) {
            try {
              checkString(d.url, false, field + '.url');
            } catch (e) {
              throw e;
            }
          }
        } catch (e) {
          throw e;
        }
      }
    }
    checkString(d.mime_type, true, field + '.mime_type');
    if (d.mime_type === undefined) {
      d.mime_type = null;
    }
    try {
      checkNumber(d.file_size, true, field + '.file_size');
    } catch(e) {
      try {
        checkBoolean(d.url, false, field + '.file_size');
      } catch (e) {
        throw e;
      }
    }
    try {
      checkString(d.date, true, field + '.date');
    } catch(e) {
      try {
        checkBoolean(d.url, false, field + '.date');
      } catch (e) {
        throw e;
      }
    }
    return new FilesEntityProxy(d);
  }
}

export class FrameioMediaEntityProxy implements FrameioMediaEntity {
  public readonly id: string | null;
  public readonly name: string | null;
  public readonly fps: number | null;
  public readonly thumbnail_url: string | null;
  public readonly type: string | null;
  public readonly version: number | null;
  public readonly media_url: string | null;
  public readonly versions: FrameioMediaEntityProxy[] | null;
  public readonly formats: Record<string, { url: string; width: number; height: number }> | null;
  public readonly folder: string | null;

  private constructor(d: any) {
    this.id = d.id;
    this.name = d.name;
    this.fps = d.fps;
    this.thumbnail_url = d.thumbnail_url;
    this.type = d.type;
    this.version = d.version;
    this.media_url = d.media_url;
    this.versions = d.versions;
    this.formats = d.formats;
    this.folder = d.folder;
  }

  public static Parse(d: string): FrameioMediaEntityProxy {
    return FrameioMediaEntityProxy.Create(JSON.parse(d));
  }

  public static Create(
    d: any,
    field: string = 'root',
  ): FrameioMediaEntityProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkString(d.id, true, field + '.id');
    if (d.id === undefined) {
      d.id = null;
    }
    checkString(d.name, true, field + '.name');
    if (d.name === undefined) {
      d.name = null;
    }
    checkNumber(d.fps, true, field + '.fps');
    if (d.fps === undefined) {
      d.fps = null;
    }
    checkString(d.thumbnail_url, true, field + '.thumbnail_url');
    if (d.thumbnail_url === undefined) {
      d.thumbnail_url = null;
    }
    checkString(d.type, true, field + '.type');
    if (d.type === undefined) {
      d.type = null;
    }
    checkNumber(d.version, true, field + '.version');
    if (d.version === undefined) {
      d.version = null;
    }
    checkString(d.media_url, true, field + '.media_url');
    if (d.media_url === undefined) {
      d.media_url = null;
    }
    checkArray(d.versions, field + '.versions');
    if (d.versions) {
      for (let i = 0; i < d.versions.length; i++) {
        d.versions[i] = FrameioMediaEntityProxy.Create(
          d.versions[i],
          field + '.versions' + '[' + i + ']',
        );
      }
    }
    if (d.versions === undefined) {
      d.versions = null;
    }
    checkString(d.folder, true, field + ".folder");
    if (d.folder === undefined) {
      d.folder = null;
    }

    // Code written by hand
    if (d.formats === undefined) {
      d.formats = null;
    } else if (typeof d.formats !== 'object') {
      throwNotObject(d.formats, field + '.formats', true);
    } else {
      for (const format of Object.keys(d.formats)) {
        if (!(format in d.formats)) {
          continue;
        }
        if (d.formats[format] === undefined || typeof d.formats[format] !== 'object') {
          delete d.formats[format];
        } else {
          checkString(d.formats[format].url, true, field + '.formats[format].url');
          if (d.formats[format].url === undefined || d.formats[format].url === null) {
            delete d.formats[format];
          } else {
            checkNumber(d.formats[format].width, true, field + '.formats[format].url');
            checkNumber(d.formats[format].height, true, field + '.formats[format].url');
          }
        }
      }
    }
    return new FrameioMediaEntityProxy(d);
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, 'non-nullable object', false);
}

function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable);
}

function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable);
}

function checkArray(d: any, field: string): void {
  if (!Array.isArray(d) && d !== null && d !== undefined) {
    errorHelper(field, d, 'array', true);
  }
}

function checkNumber(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'number' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'number', nullable);
  }
}

function checkBoolean(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'boolean' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'boolean', nullable);
  }
}

function checkString(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'string' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'string', nullable);
  }
}

function errorHelper(
  field: string,
  d: any,
  type: string,
  nullable: boolean,
): never {
  if (nullable) {
    type += ', null, or undefined';
  }
  throw new TypeError(
    'Expected ' +
      type +
      ' at ' +
      field +
      ' but found:\n' +
      JSON.stringify(d) +
      '\n\nFull object:\n' +
      JSON.stringify(obj),
  );
}
