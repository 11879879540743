import React, { useCallback, useState } from 'react';
import { IonCol, IonContent, IonGrid, IonPage, IonText } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { validate as validateEmail } from 'email-validator';
import messages from './messages';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useRequestPasswordReset } from '../../api';
import {
  ErrorMessage,
  InputField,
  StyledButton,
  VideoContainer,
} from '../../shared/styles';
import {
  ButtonRow,
  CenterCol,
  CenterRow,
  CenterRowLarge,
  ForgotButtonRow,
  Form,
  HorizontalRule,
  HorizontalRuleRow,
  HorizontalRuleTextCol,
  ImprintCol,
  ImprintRow,
  StyledLinkText,
  TopRow,
} from '../../shared/login.styles';

const ForgotPassword: React.FC = () => {
  /* eslint-disable */
  const intl = useIntl();
  const mutation = useRequestPasswordReset();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [message, setMessage] = useState('');

  const onSubmit = useCallback(
    (data: any) => {
      if (data.email) {
        setLoading(true);
        mutation.mutate(
          { email: data.email },
          {
            onSettled: async (req, err) => {
              setLoading(false);
              if (!!req && req.ok && !err) {
                const responseData = await req.json();
                if (
                  !responseData.hasOwnProperty('ok') ||
                  responseData.ok === false
                ) {
                  setMessage(intl.formatMessage(messages.resetError));
                  return;
                }

                setMessage(intl.formatMessage(messages.resetMailSent));
              } else {
                setMessage(intl.formatMessage(messages.resetError));
              }
            },
          },
        );
      }
    },
    [intl, setMessage, setLoading, mutation],
  );

  const email = register('email', {
    validate: (value) => {
      if (!value || value.trim().length === 0) {
        return intl.formatMessage(messages.fieldRequired);
      }

      if (!validateEmail(value)) {
        return intl.formatMessage(messages.invalidEmail);
      }

      return true;
    },
  });

  const showError = useCallback(
    (_fieldName: string) => {
      return (
        (errors as any)[_fieldName] && (
          <ErrorMessage>{errors[_fieldName]?.message}</ErrorMessage>
        )
      );
    },
    [errors],
  );

  const login = useCallback(() => {
    history.push('/login');
  }, [history]);

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <CenterRow>
            <VideoContainer sizeXs="12">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <TopRow>
                  <CenterCol sizeLg="5" sizeXs="10">
                    <InputField
                      placeholder={intl.formatMessage(messages.email)}
                      type="text"
                      onIonChange={email.onChange}
                      onIonBlur={email.onBlur}
                      name={email.name}
                      ref={email.ref}
                    />
                  </CenterCol>
                </TopRow>

                <CenterRow>
                  <CenterCol sizeLg="5" sizeXs="10">
                    {showError('email')}
                  </CenterCol>
                </CenterRow>

                <ButtonRow>
                  <IonCol sizeLg="5" sizeXs="10">
                    <StyledButton
                      type="submit"
                      expand="full"
                      shape="round"
                      color="primary"
                      disabled={loading}
                    >
                      {intl.formatMessage(messages.resetPassword)}
                    </StyledButton>
                  </IonCol>
                </ButtonRow>
              </Form>

              {message && (
                <ForgotButtonRow>
                  <ImprintCol sizeLg="5" sizeXs="10">
                    <IonText className="link" color="dark">
                      {message}
                    </IonText>
                  </ImprintCol>
                </ForgotButtonRow>
              )}

              <HorizontalRuleRow>
                <IonCol sizeXs="3" sizeLg="2">
                  <HorizontalRule />
                </IonCol>
                <IonCol sizeXs="3" sizeLg="1">
                  <CenterRow>
                    <HorizontalRuleTextCol sizeLg="12">
                      <StyledLinkText color="dark">
                        {intl.formatMessage(messages.or)}
                      </StyledLinkText>
                    </HorizontalRuleTextCol>
                  </CenterRow>
                </IonCol>
                <IonCol sizeXs="3" sizeLg="2">
                  <HorizontalRule />
                </IonCol>
              </HorizontalRuleRow>

              <CenterRowLarge>
                <IonCol sizeLg="5" sizeXs="10">
                  <StyledButton
                    type="submit"
                    expand="full"
                    shape="round"
                    color="primary"
                    disabled={loading}
                    onClick={login}
                  >
                    {intl.formatMessage(messages.login)}
                  </StyledButton>
                </IonCol>
              </CenterRowLarge>

              <ImprintRow>
                <ImprintCol sizeLg="5" sizeXs="10">
                  <StyledLinkText color="dark">
                    <a href={process.env.REACT_APP_IMPRINT_URL}>
                      {intl.formatMessage(messages.imprintText)}
                    </a>
                    &nbsp;/&nbsp;
                    <a href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}>
                      {intl.formatMessage(messages.termsText)}
                    </a>
                  </StyledLinkText>
                </ImprintCol>
              </ImprintRow>
              <video autoPlay muted loop playsInline>
                <source src="/assets/video/intro.mp4" type="video/mp4" />
              </video>
            </VideoContainer>
          </CenterRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
