import {
  ERROR_NOTIFICATION,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  UPDATE_PROFILE,
} from '../types';

export const login =
  (data: { email: string; password: string }) => async (dispatch: Function) => {
    dispatch({
      type: LOGIN_LOADING,
    });
    // set header
    const headers = {
      'Content-Type': 'application/json',
    };
    // set body
    const body = JSON.stringify(data);
    let error;
    try {
      // login user

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
        {
          method: 'POST',
          headers,
          body,
        },
      );
      const json = await response.json();

      if ('token' in json) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: json.token,
        });

        return { status: 'success' };
      }

      if ('message' in json) {
        error = json.message;
      }
    } catch (e) {
      error = e;
    }
    dispatch({
      type: ERROR_NOTIFICATION,
      payload: error,
    });
    return { status: 'error' };
  };

export const register =
  (data: { phone: string; email: string; password: string }) =>
  async (dispatch: Function) => {
    dispatch({
      type: LOGIN_LOADING,
    });
    // set header
    const headers = {
      'Content-Type': 'application/json',
    };
    // set body
    const body = JSON.stringify(data);
    let error;
    try {
      // login user

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/auth/register`,
        {
          method: 'POST',
          headers,
          body,
        },
      );

      const json = await response.json();
      if ('created' in json && json.created === true) {
        return { status: 'success' };
      }
    } catch (e) {
      error = e;
    }

    dispatch({
      type: ERROR_NOTIFICATION,
      payload: true,
    });
    return { status: 'error' };
  };

export const updateProfile = (data: any) => async (dispatch: Function) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  // set body
  const body = JSON.stringify(data);
  try {
    dispatch({
      type: UPDATE_PROFILE,
      payload: data,
    });
    return { status: 'success' };
  } catch (error) {
    dispatch({
      type: ERROR_NOTIFICATION,
      payload: {
        title: `Failed`,
        message: (error as any)?.response?.data?.message?.message,
        severity: 'error',
      },
    });
    return { status: 'error' };
  }
};
