// Stores the currently-being-typechecked object for error messages.
let obj: any = null;
export class CompanyProxy {
  public readonly id: number;
  public readonly name: string;
  public readonly employees: string[] | null;
  public readonly company_secret: string;
  public static Parse(d: string): CompanyProxy {
    return CompanyProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): CompanyProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkNumber(d.id, false, field + '.id');
    checkString(d.name, false, field + '.name');
    checkArray(d.employees, field + '.employees');
    if (d.employees) {
      for (let i = 0; i < d.employees.length; i++) {
        checkString(
          d.employees[i],
          false,
          field + '.employees' + '[' + i + ']',
        );
      }
    }
    if (d.employees === undefined) {
      d.employees = null;
    }
    checkString(d.company_secret, false, field + '.company_secret');
    return new CompanyProxy(d);
  }
  private constructor(d: any) {
    this.id = d.id;
    this.name = d.name;
    this.employees = d.employees;
    this.company_secret = d.company_secret;
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, 'non-nullable object', false);
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable);
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable);
}
function checkArray(d: any, field: string): void {
  if (!Array.isArray(d) && d !== null && d !== undefined) {
    errorHelper(field, d, 'array', true);
  }
}
function checkNumber(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'number' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'number', nullable);
  }
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'string' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'string', nullable);
  }
}
function errorHelper(
  field: string,
  d: any,
  type: string,
  nullable: boolean,
): never {
  if (nullable) {
    type += ', null, or undefined';
  }
  throw new TypeError(
    'Expected ' +
      type +
      ' at ' +
      field +
      ' but found:\n' +
      JSON.stringify(d) +
      '\n\nFull object:\n' +
      JSON.stringify(obj),
  );
}
