import styled from 'styled-components';
import { IonButtons, IonHeader, IonText, IonToolbar } from '@ionic/react';

export const Buttons = styled(IonButtons)`
  margin-right: 1rem;
`;

export const Toolbar = styled(IonToolbar)<{ alignBottom?: boolean }>`
  --min-height: 100px;
  --padding-bottom: ${(props) => (props.alignBottom ? '15px' : '0')};
  --padding-start: 20px;
  display: flex;
  align-items: flex-end;
`;

export const Text = styled(IonText)<{ styles?: any }>`
  font-family: BaiJamjureeBold, sans-serif;
  font-size: 25px;
  ${(props) => props.styles ?? ''};
`;

export const HeaderContainer = styled(IonHeader)``;
