import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useQuery } from 'react-query';
import { UserProxy } from '../../model';
import { wpFetch } from '../wp-fetch';

export const QUERY_KEY_USER = 'user';
const PROFILE_ENDPOINT = 'users/me';

const TIME_TO_RE_FETCH = 1000 * 60;
const TIME_TO_GARBAGE_COLLECT = 1000 * 15;

const fetchUser = (token: string): Promise<UserProxy> =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${PROFILE_ENDPOINT}${getDevelopQueryParam()}`,
    {
      headers: getHeaders(token),
    },
  )
    .then((res) => res.json())
    .then((data) => UserProxy.Create(data));

export const useUser = (token: string, additionalOptions?: any) => {
  return useQuery(QUERY_KEY_USER, () => fetchUser(token), {
    staleTime: TIME_TO_RE_FETCH,
    cacheTime: TIME_TO_GARBAGE_COLLECT,
    ...additionalOptions,
  });
};
