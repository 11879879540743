import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const projectEndpoint = 'content/project';
export const createReviewLink = async (
  token: string,
  projectId: number,
  assetId: string,
  asAdmin = false
) =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${projectEndpoint}/${projectId}${getDevelopQueryParam()}${asAdmin ? '&admin=true': ''}`,
    {
      headers: getHeaders(token),
      method: 'POST',
      body: JSON.stringify({
        reviewLink: {
          assetId: assetId,
        },
      }),
    },
  );

export const useCreateReviewLink = () => {
  return useMutation(
    ({
      token,
      projectId,
      assetId,
      asAdmin = false,
    }: {
      token: string;
      projectId: number;
      assetId: string;
      asAdmin?: boolean
    }) => {
      return createReviewLink(token, projectId, assetId, asAdmin);
    },
  );
};
