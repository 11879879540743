import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const companyEndpoint = 'content/company';
export const patchCompany = async (token: string, secret: string) =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${companyEndpoint}${getDevelopQueryParam()}`,
    {
      headers: getHeaders(token),
      method: 'PATCH',
      body: JSON.stringify({ secret }),
    },
  );

export const useJoinCompany = () => {
  return useMutation(({ token, secret }: { token: string; secret: string }) => {
    return patchCompany(token, secret);
  });
};
