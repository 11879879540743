import React, { useEffect, useRef } from 'react';
import { IonBackButton } from '@ionic/react';
import { Buttons, HeaderContainer, Text, Toolbar } from './Header.styles';

const Header: React.FC<{
  name: string;
  backButton?: boolean;
  styles?: any;
  alignBottom?: boolean;
}> = ({ name, backButton = false, alignBottom, children, styles }) => {

  const startButtons = backButton ? (
    <Buttons slot="start">
      <IonBackButton defaultHref="/" text="" />
    </Buttons>
  ) : (
    <></>
  );

  return (
    <HeaderContainer>
      <Toolbar color="primary">
        {startButtons}

        <Text styles={styles}>{name}</Text>

        {children}
      </Toolbar>
    </HeaderContainer>
  );
};

Header.defaultProps = {
  alignBottom: true,
};

export default Header;
