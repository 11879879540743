import React, { lazy, Suspense, useEffect, useState } from 'react';
import {
  IonApp,
  IonContent,
  IonLoading,
  IonPage,
  IonRouterOutlet,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Provider, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/fonts.scss';
import './theme/variables.scss';
import './theme/global.scss';

import { PersistGate } from 'redux-persist/lib/integration/react';
import storeConfig from './redux/store';
import { IntlProvider } from 'react-intl';
import en from './translations/en';
import de from './translations/de';
import { RootState } from './redux/reducers/RootReducer';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import ForgotPassword from './pages/ForgotPassowrd/ForgotPassword';
import PublicRoute from './PublicRoutes';
import ProtectedRoute from './ProtectedRoutes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppUrlListener from './AppUrlListener';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import NotFound from './pages/NotFound/NotFound';
import { useAppDispatch } from './redux/storeHooks';
import { SET_LANGUAGE } from './redux/types';
import { GlobalErrorBoundary } from './components/GlobalErrorBoundary/GlobalErrorBoundary';

const Tabs = lazy(() => import('./components/Layout/Tabs'));

document.addEventListener('DOMContentLoaded', () => {
  // Always set status bar to dark theme
  StatusBar.setStyle({ style: Style.Dark }).catch(console.error);
});

const AppRouting = () => {
  const msg: any = {
    en: en,
    de: de,
  };
  let languageReducer = useSelector((state: RootState) => state.language);
  let dispatch = useAppDispatch();
  const [locale, setLocale] = useState(languageReducer?.language ?? 'de');
  useEffect(() => {
    setLocale(languageReducer?.language ?? 'de');
    // eslint-disable-next-line
  }, [languageReducer?.language, localStorage.getItem('language')]);

  useEffect(() => {
    if (dispatch) {
      Device.getLanguageCode().then((language) => {
        let languageCode = language.value;
        if (languageCode.length > 2) {
          languageCode = languageCode.substring(0, 2);
        }
        dispatch({
          type: SET_LANGUAGE,
          payload: languageCode,
        });
      });
    }
  }, [dispatch]);

  return (
    <IntlProvider locale={locale} messages={msg[locale]}>
      <GlobalErrorBoundary>
        <Suspense
          fallback={
            <IonPage>
              <IonContent>
                <IonLoading isOpen={true} />
              </IonContent>
            </IonPage>
          }
        >
          <IonReactRouter>
            <AppUrlListener />
            <IonRouterOutlet>
              <ProtectedRoute path="/tabs" component={Tabs} />

              <PublicRoute exact path="/login" component={Login} />
              <PublicRoute exact path="/signup" component={Signup} />
              <PublicRoute
                exact
                path="/password-reset/:key"
                component={PasswordReset}
              />
              <PublicRoute
                exact
                path="/forgot-password"
                component={ForgotPassword}
              />

              <PublicRoute exact path="/">
                <Redirect to="/login" />
              </PublicRoute>

              {/* 404 - Route */}
              <PublicRoute component={NotFound} />
            </IonRouterOutlet>
          </IonReactRouter>
        </Suspense>
      </GlobalErrorBoundary>
    </IntlProvider>
  );
};

// export type RootState = ReturnType<typeof storeConfig.store.getState>;
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof storeConfig.store.dispatch;

const App = () => {
  const { store, persistor } = storeConfig;
  const queryClient = new QueryClient();

  return (
    <IonApp>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate persistor={persistor}>
            <AppRouting />
          </PersistGate>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </IonApp>
  );
};

export default App;
