import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useQuery } from 'react-query';
import { wpFetch } from '../wp-fetch';

export const QUERY_KEY_COMMENTS = 'comments';
const COMMENTS_ENDPOINT = 'content/comments';

const TIME_TO_RE_FETCH = 1000 * 60;
const TIME_TO_GARBAGE_COLLECT = 1000 * 15;

const fetchComments = (token: string, assetId: string, asAdmin = false): Promise<any[]> =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${COMMENTS_ENDPOINT}/${assetId}${getDevelopQueryParam()}${asAdmin ? '&admin=true': ''}`,
    {
      headers: getHeaders(token),
    },
  )
    .then((res) => res.json())
    .then((data) => {
      if ('ok' in data && data.ok === true && 'comments' in data) {
        return data.comments;
      }
      return [];
    });

export const useComments = (
  token: string,
  assetId: string,
  asAdmin = false,
  additionalOptions?: any,
) => {
  return useQuery(
    [QUERY_KEY_COMMENTS, assetId, asAdmin],
    () => fetchComments(token, assetId, asAdmin),
    {
      staleTime: TIME_TO_RE_FETCH,
      cacheTime: TIME_TO_GARBAGE_COLLECT,
      ...additionalOptions,
    },
  );
};
