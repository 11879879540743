/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'more_film.containers.login';

export default defineMessages({
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Login',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Phone Number or Email Address',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Create New Account',
  },
  or: {
    id: `${scope}.or`,
    defaultMessage: 'or',
  },
  forgot: {
    id: `${scope}.forgotPassword`,
    defaultMessage: 'Forgot Password?',
  },
  imprintText: {
    id: `${scope}.imprintText`,
    defaultMessage: 'Imprint',
  },
  termsText: {
    id: `${scope}.termsText`,
    defaultMessage: 'Terms and Conditions',
  },
  fieldRequired: {
    id: `${scope}.fieldRequired`,
    defaultMessage: 'This field is required.',
  },
  invalidEmail: {
    id: `${scope}.invalidEmail`,
    defaultMessage: 'Please enter a valid email address.',
  },
  loginError: {
    id: `${scope}.loginError`,
    defaultMessage: 'Login Failed.',
  },
  loginErrorAction: {
    id: `${scope}.registerErrorAction`,
    defaultMessage: 'Close',
  },
});
