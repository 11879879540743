import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const projectEndpoint = 'content/project';
export const acceptOffer = async (
  token: string,
  projectId: number,
  signature: string,
  asAdmin: boolean,
) =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${projectEndpoint}/${projectId}${getDevelopQueryParam()}${asAdmin ? '&admin=true': ''}`,
    {
      headers: getHeaders(token),
      method: 'POST',
      body: JSON.stringify({ signature }),
    },
  );

export const useAcceptOffer = () => {
  return useMutation(
    ({
      token,
      projectId,
      signature,
      asAdmin = false
    }: {
      token: string;
      projectId: number;
      signature: string;
      asAdmin?: boolean;
    }) => {
      return acceptOffer(token, projectId, signature, asAdmin);
    },
  );
};
