import { Feedback } from '../../model';
import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const feedbackEndpoint = 'content/feedback';
export const postFeedback = async (token: string, feedback: Feedback) =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${feedbackEndpoint}${getDevelopQueryParam()}`,
    {
      headers: getHeaders(token),
      method: 'POST',
      body: JSON.stringify(feedback),
    },
  );

export const useCreateFeedback = () => {
  return useMutation(
    ({ token, feedback }: { token: string; feedback: Feedback }) => {
      return postFeedback(token, feedback);
    },
  );
};
