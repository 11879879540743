import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const userResetEndpoint = 'users/update-password';
export const setPassword = async (resetToken: string, password: string) =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${userResetEndpoint}${getDevelopQueryParam()}`,
    {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        reset_token: resetToken,
        password,
      }),
    },
  );

export const useSetPassword = () => {
  return useMutation(
    ({ resetToken, password }: { resetToken: string; password: string }) => {
      return setPassword(resetToken, password);
    },
  );
};
