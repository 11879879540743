import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useQuery } from 'react-query';
import { wpFetch } from '../wp-fetch';

export const QUERY_KEY_APP_SETTINGS = 'settings';
const COMMENTS_ENDPOINT = 'app/settings';

const TIME_TO_RE_FETCH = 1000 * 60;
const TIME_TO_GARBAGE_COLLECT = 1000 * 15;

const fetchSettings = (): Promise<any> =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${COMMENTS_ENDPOINT}${getDevelopQueryParam()}`,
    {
      headers: getHeaders(),
    },
  ).then((res) => res.json());

export const useSettings = (additionalOptions?: any) => {
  return useQuery(QUERY_KEY_APP_SETTINGS, () => fetchSettings(), {
    staleTime: TIME_TO_RE_FETCH,
    cacheTime: TIME_TO_GARBAGE_COLLECT,
    ...additionalOptions,
  });
};
