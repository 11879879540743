import { SET_PROJECTS_LIST, SET_SINGLE_PROJECT } from '../types';

const initialState = {
  projects: [],
  singleProject: null,
};

const projectsReducer = function (state = initialState, action: any) {
  switch (action.type) {
    case SET_PROJECTS_LIST: {
      return {
        ...state,
        projects: action.payload,
      };
    }
    case SET_SINGLE_PROJECT: {
      return {
        ...state,
        singleProject: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default projectsReducer;
