import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useQuery } from 'react-query';
import { Company, CompanyProxy } from '../../model';
import { wpFetch } from '../wp-fetch';

export const QUERY_KEY_COMPANIES = 'companies';
const COMPANY_ENDPOINT = 'content/company';

const TIME_TO_RE_FETCH = 1000 * 60;
const TIME_TO_GARBAGE_COLLECT = 1000 * 15;

const fetchCompanies = async (token: string, admin = false): Promise<CompanyProxy[]> => {
  const response = await wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${COMPANY_ENDPOINT}${getDevelopQueryParam()}${admin ? '&admin=true' : ''}`,
    {
      headers: getHeaders(token),
    },
  );

  if (response.status !== 200) {
    const data = await response.json();
    throw new Error(data.message ?? '');
  }

  const data = await response.json();
  return data.map((company: Company) => CompanyProxy.Create(company));
};

export const useCompanies = (token: string, admin = false, additionalOptions?: any) => {
  return useQuery([QUERY_KEY_COMPANIES, admin], () => fetchCompanies(token, admin), {
    staleTime: TIME_TO_RE_FETCH,
    cacheTime: TIME_TO_GARBAGE_COLLECT,
    ...additionalOptions,
  });
};
