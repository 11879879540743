import { useQuery } from 'react-query';
import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { StaffProxy } from '../../model';
import { wpFetch } from '../wp-fetch';

const STAFF_ENDPOINT = 'content/staff';
export const QUERY_KEY_STAFF = 'staff';

const TIME_TO_RE_FETCH = 1000 * 60 * 5;
const TIME_TO_GARBAGE_COLLECT = 1000 * 60;

const fetchStaffById = (token: string, id?: number): Promise<StaffProxy> => {
  if (!id) {
    throw Error('ID must have a valid value!');
  }
  return wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${STAFF_ENDPOINT}/${id}${getDevelopQueryParam()}`,
    { headers: getHeaders(token) },
  )
    .then((res) => res.json())
    .then((data) => StaffProxy.Create(data));
};

const fetchAllStaff = (token: string): Promise<StaffProxy[]> => {
  return wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${STAFF_ENDPOINT}${getDevelopQueryParam()}`,
    {
      headers: getHeaders(token),
    },
  )
    .then((res) => res.json())
    .then((data) => data.map((staff: any) => StaffProxy.Create(staff)));
};

export const useStaffById = (
  token: string,
  id?: number,
  additionalOptions?: any,
) => {
  return useQuery([QUERY_KEY_STAFF, id], () => fetchStaffById(token, id), {
    staleTime: TIME_TO_RE_FETCH,
    cacheTime: TIME_TO_GARBAGE_COLLECT,
    ...additionalOptions,
  });
};

export const useStaff = (token: string, additionalOptions?: any) => {
  return useQuery(QUERY_KEY_STAFF, () => fetchAllStaff(token), {
    staleTime: TIME_TO_RE_FETCH,
    cacheTime: TIME_TO_GARBAGE_COLLECT,
    ...additionalOptions,
  });
};
