import styled, { css } from 'styled-components';
import {
  IonButton,
  IonCol,
  IonInput,
  IonText,
  IonTextarea,
} from '@ionic/react';

export const VideoContainer = styled(IonCol)`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;
    z-index: -1000;
    opacity: 0.5;
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const InputStyles = css`
  --background: #fff;
  width: 100%;
  --placeholder-opacity: 0.2;
  border: 1px solid #000000;
  --padding-bottom: 10px;
  --padding-top: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
`;

export const InputField = styled(IonInput)`
  ${InputStyles}
`;

export const InputTextarea = styled(IonTextarea)`
  ${InputStyles}
`;

export const ErrorMessage = styled(IonText)`
  color: red;
  display: block;
  width: 100%;
  padding-left: 17px;
  font-size: smaller;
`;

export const StyledButton = styled(IonButton)`
  font-family: BaiJamjureeBold, sans-serif;
`;
