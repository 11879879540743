import { useQuery } from 'react-query';
import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { ProjectRecord, ProjectRecordProxy, ProjectProxy, FrameioMediaEntityProxy } from '../../model';
import { FrameIoMediaCountProxy } from '../../model/frame-io-count.proxy';
import { wpFetch } from '../wp-fetch';

const PROJECT_ENDPOINT = 'content/project';
export const QUERY_KEY_PROJECTS_ALL = 'projects_all';
export const QUERY_KEY_PROJECTS_SINGLE = 'projects_single';
export const QUERY_KEY_PROJECTS_SINGLE_MEDIA = 'projects_single_media';

const TIME_TO_RE_FETCH_ALL = 1000 * 60 * 5;
const TIME_TO_GARBAGE_COLLECT_ALL = 1000 * 60;

const TIME_TO_RE_FETCH_SINGLE = 1000 * 60;
const TIME_TO_GARBAGE_COLLECT_SINGLE = 1000 * 15;

const TIME_TO_RE_FETCH_SINGLE_MEDIA = 1000 * 60;
const TIME_TO_GARBAGE_COLLECT_SINGLE_MEDIA = 1000 * 15;

const fetchProjects = (token: string, companyId?: string): Promise<ProjectRecordProxy[]> =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${PROJECT_ENDPOINT}${getDevelopQueryParam()}&frameIO=false${!!companyId ? `&admin=true&companyId=${companyId}` : ''}`,
    {
      headers: getHeaders(token),
    },
  )
    .then((res) => res.json())
    .then((data) =>
      data
        .map((project: any) => {
          try {
            return ProjectRecordProxy.Create(project);
          } catch {
            return null;
          }
        })
        .filter((project: ProjectRecord) => project !== null),
    );

const fetchProject = async (
  token: string,
  id: number,
  asAdmin = false
): Promise<ProjectProxy | null> => {
  const response = await wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${PROJECT_ENDPOINT}/${id}${getDevelopQueryParam()}&frameIO=false${asAdmin ? '&admin=true' : ''}`,
    { headers: getHeaders(token) },
  );

  if (response.status !== 200) {
    const data = await response.json();
    throw new Error(data.message ?? '');
  }

  try {
    return ProjectProxy.Create(await response.json());
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchProjectMediaCount = async (
  token: string,
  id: number,
  asAdmin = false
): Promise<FrameIoMediaCountProxy | null> => {
  const response = await wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${PROJECT_ENDPOINT}/${id}/media-count${getDevelopQueryParam()}${asAdmin ? '&admin=true' : ''}`,
    { headers: getHeaders(token) },
  );

  if (response.status !== 200) {
    const data = await response.json();
    throw new Error(data.message ?? '');
  }

  try {
    return FrameIoMediaCountProxy.Create(await response.json());
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchProjectMedia = async (
  token: string,
  id: number,
  asAdmin = false,
): Promise<FrameioMediaEntityProxy[] | null> => {
  const response = await wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${PROJECT_ENDPOINT}/${id}/media${getDevelopQueryParam()}${asAdmin ? '&admin=true': ''}`,
    { headers: getHeaders(token) },
  );

  if (response.status !== 200) {
    const data = await response.json();
    throw new Error(data.message ?? '');
  }

  try {const data = await response.json();
    return data.media.map((media: any) => FrameioMediaEntityProxy.Create(media));
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const useAllProjects = (token: string, companyId?: string, additionalOptions?: any) => {
  return useQuery([QUERY_KEY_PROJECTS_ALL, companyId], () => fetchProjects(token, companyId), {
    staleTime: TIME_TO_RE_FETCH_ALL,
    cacheTime: TIME_TO_GARBAGE_COLLECT_ALL,
    ...additionalOptions,
  });
};

export const useProject = (
  token: string,
  id: number,
  asAdmin = false,
  additionalOptions?: any,
) => {
  return useQuery(
    [QUERY_KEY_PROJECTS_SINGLE, id, asAdmin],
    () => fetchProject(token, id, asAdmin),
    {
      staleTime: TIME_TO_RE_FETCH_SINGLE,
      cacheTime: TIME_TO_GARBAGE_COLLECT_SINGLE,
      ...additionalOptions,
    },
  );
};

export const useProjectMedia = (
  token: string,
  id: number,
  asAdmin = false,
  additionalOptions?: any,
) => {
  return useQuery(
    [QUERY_KEY_PROJECTS_SINGLE_MEDIA, id, asAdmin],
    () => fetchProjectMedia(token, id, asAdmin),
    {
      staleTime: TIME_TO_RE_FETCH_SINGLE_MEDIA,
      cacheTime: TIME_TO_GARBAGE_COLLECT_SINGLE_MEDIA,
      ...additionalOptions,
    },
  );
};

export const useProjectMediaVideo = (
  token: string,
  id: number,
  asAdmin = false,
  additionalOptions?: any,
) => {
  return useQuery(
    [QUERY_KEY_PROJECTS_SINGLE_MEDIA, id, asAdmin],
    () => fetchProjectMedia(token, id, asAdmin),
    {
      staleTime: Infinity,
      cacheTime: TIME_TO_GARBAGE_COLLECT_SINGLE_MEDIA,
      ...additionalOptions,
    },
  );
};
