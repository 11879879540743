// Stores the currently-being-typechecked object for error messages.
import { User } from './user';

let obj: any = null;
export class UserProxy implements User {
  public readonly id: number;
  public readonly name: string;
  public readonly first_name: string;
  public readonly last_name: string;
  public readonly companies: number[] | null;
  public readonly avatar: string;
  public readonly login: string;
  public readonly email: string;
  public readonly address: string;
  public readonly country: string;
  public readonly position: string;
  public readonly phone: string;
  public readonly mobile: string;
  public readonly website: string;
  public readonly roles: string[] | null;
  public static Parse(d: string): UserProxy {
    return UserProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): UserProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkNumber(d.id, false, field + '.id');
    checkString(d.name, false, field + '.name');
    checkString(d.first_name, false, field + '.first_name');
    checkString(d.last_name, false, field + '.last_name');
    checkArray(d.companies, field + '.companies');
    if (d.companies) {
      for (let i = 0; i < d.companies.length; i++) {
        checkNumber(
          d.companies[i],
          false,
          field + '.companies' + '[' + i + ']',
        );
      }
    }
    if (d.companies === undefined) {
      d.companies = null;
    }
    checkString(d.avatar, false, field + '.avatar');
    checkString(d.login, false, field + '.login');
    checkString(d.email, false, field + '.email');
    checkString(d.address, false, field + '.address');
    checkString(d.country, false, field + '.country');
    checkString(d.position, false, field + '.position');
    checkString(d.phone, false, field + '.phone');
    checkString(d.mobile, false, field + '.mobile');
    checkString(d.website, false, field + '.website');
    checkArray(d.roles, field + '.roles');
    if (d.roles) {
      for (let i = 0; i < d.roles.length; i++) {
        checkString(d.roles[i], false, field + '.roles' + '[' + i + ']');
      }
    }
    if (d.roles === undefined) {
      d.roles = null;
    }
    return new UserProxy(d);
  }
  private constructor(d: any) {
    this.id = d.id;
    this.name = d.name;
    this.first_name = d.first_name;
    this.last_name = d.last_name;
    this.companies = d.companies;
    this.avatar = d.avatar;
    this.login = d.login;
    this.email = d.email;
    this.address = d.address;
    this.country = d.country;
    this.position = d.position;
    this.phone = d.phone;
    this.mobile = d.mobile;
    this.website = d.website;
    this.roles = d.roles;
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, 'non-nullable object', false);
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable);
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable);
}
function checkArray(d: any, field: string): void {
  if (!Array.isArray(d) && d !== null && d !== undefined) {
    errorHelper(field, d, 'array', true);
  }
}
function checkNumber(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'number' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'number', nullable);
  }
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'string' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'string', nullable);
  }
}
function errorHelper(
  field: string,
  d: any,
  type: string,
  nullable: boolean,
): never {
  if (nullable) {
    type += ', null, or undefined';
  }
  throw new TypeError(
    'Expected ' +
      type +
      ' at ' +
      field +
      ' but found:\n' +
      JSON.stringify(d) +
      '\n\nFull object:\n' +
      JSON.stringify(obj),
  );
}
