//auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const ERROR_NOTIFICATION = 'ERROR_NOTIFICATION';
export const CLEAR_ERROR_NOTIFICATION = 'CLEAR_ERROR_NOTIFICATION';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGOUT = 'LOGOUT';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
//LANGUAGE
export const SET_LANGUAGE = 'SET_LANGUAGE';
//FEEDBACK
export const FEEDBACK_SUCCESS_MESSAGE = 'FEEDBACK_SUCCESS_MESSAGE';
//CONTACTS
export const SET_CONTACTS_LIST = 'SET_CONTACTS_LIST';
//PROJECTS
export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST';
export const SET_SINGLE_PROJECT = 'SET_SINGLE_PROJECT';
//comments

export const SET_COMMENTS_LIST = 'SET_COMMENTS_LIST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
