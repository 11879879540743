export const getHeaders = (token?: string): any => {
  if (!token) {
    return {
      'Content-Type': 'application/json',
    };
  }
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

export const getDevelopQueryParam = (includeQuestionMark = true) => {
  if (process.env.NODE_ENV === 'development') {
    return (includeQuestionMark ? '?' : '') + 'dev';
  } else {
    return includeQuestionMark ? '?' : '';
  }
};
