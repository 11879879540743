import { UploadField } from '../../model';
import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const projectEndpoint = 'content/project';
export const patchField = async (token: string, field: UploadField, asAdmin = false) => {
  const formData = new FormData();
  let metaData: any;

  if (field.content) {
    metaData = { uuid: field.fieldUuid, content: field.content };
  } else {
    metaData = {
      uuid: field.fieldUuid,
    };
  }

  if (field.file) {
    metaData = { ...metaData, files: ['file'] };
    formData.append('file', field.file, field.file.name);
  }

  formData.append('fields', JSON.stringify([metaData]));

  return wpFetch(
    `${process.env.REACT_APP_API_BASE_URL}/${projectEndpoint}/${
      field.projectId
    }${getDevelopQueryParam()}${asAdmin ? '&admin=true': ''}`,
    {
      headers: { Authorization: getHeaders(token).Authorization },
      method: 'POST',
      body: formData,
    },
  );
};

export const usePatchField = () => {
  return useMutation(
    ({ token, field, asAdmin = false }: { token: string; field: UploadField; asAdmin?: boolean }) => {
      return patchField(token, field, asAdmin);
    },
  );
};
