import { SET_LANGUAGE } from '../types';

const initialState = {
  language: 'de',
  languagesList: ['en', 'de'],
};

// handle actions
const languageReducer = function (state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_LANGUAGE: {
      return {
        ...state,
        language: payload,
      };
    }
    default:
      return state;
  }
};

export default languageReducer;
