import styled, { css } from 'styled-components';
import { IonButton, IonCol, IonRow } from '@ionic/react';
import {
  ErrorMessage as DefaultErrorMessage,
  InputField,
  StyledButton,
} from '../../shared/styles';

const margin = css`
  margin-left: 1.5em;
  margin-right: 1.65em;
`;

export const InputRow = styled(IonRow)`
  justify-content: center;
  margin-top: 5px;
  align-items: end;
  ${margin};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorRow = styled(IonRow)`
  justify-content: center;
  ${margin};
`;

export const ErrorCol = styled(IonCol)`
  display: flex !important;
  align-items: center;
`;

export const ButtonRow = styled(IonRow)`
  justify-content: center;
  margin-top: 5px;
  ${margin};
`;

export const ButtonCol = styled(IonCol)`
  padding: 3px;
  margin-bottom: 0.5em;
`;

export const ResetButton = styled(StyledButton)`
  margin-bottom: 0;
`;

export const PasswordField = styled(InputField)`
  height: 36px;
  margin-right: 2em;
`;

export const IconButton = styled(IonButton)`
  margin-right: 0;
  --margin-end: 0;
`;

export const InfoCol = styled(IonCol)`
  padding-top: 0;
  padding-bottom: 0.245em;
  margin-top: -0.025em;
`;

export const ErrorMessage = styled(DefaultErrorMessage)`
  margin-top: 0.25em;
`;
