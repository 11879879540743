import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const userResetEndpoint = 'users/reset';
export const requestPasswordReset = async (email: string) =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${userResetEndpoint}${getDevelopQueryParam()}`,
    {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        email,
      }),
    },
  );

export const useRequestPasswordReset = () => {
  return useMutation(({ email }: { email: string }) => {
    return requestPasswordReset(email);
  });
};
