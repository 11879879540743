import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const projectEndpoint = 'content/project';
export const createComment = async (
  token: string,
  projectId: number,
  assetId: string,
  commentText: string,
  timestamp: number,
  annotations?: any[],
  asAdmin = false
) =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${projectEndpoint}/${projectId}${getDevelopQueryParam()}${asAdmin ? '&admin=true': ''}`,
    {
      headers: getHeaders(token),
      method: 'POST',
      body: JSON.stringify({
        comment: {
          asset_id: assetId,
          timestamp,
          text: commentText,
          annotations,
        },
      }),
    },
  );

export const useCreateComment = () => {
  return useMutation(
    ({
      token,
      projectId,
      assetId,
      commentText,
      timestamp,
      annotations,
     asAdmin = false,
    }: {
      token: string;
      projectId: number;
      assetId: string;
      commentText: string;
      timestamp: number;
      annotations?: any[];
      asAdmin?: boolean
    }) => {
      return createComment(
        token,
        projectId,
        assetId,
        commentText,
        timestamp,
        annotations,
        asAdmin
      );
    },
  );
};
