import React, { memo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppSelector } from './redux/storeHooks';

const PublicRoutes = ({ component: Component, auth, ...rest }: any) => {
  const hasToken = useAppSelector((state) => state.auth.token);

  return (
    <Route
      {...rest}
      render={(props) =>
        !hasToken ? <Component {...props} /> : <Redirect to="/tabs/home" />
      }
    />
  );
};

export default memo(PublicRoutes);
