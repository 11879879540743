import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener = () => {
  const history = useHistory();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log(event.url);
      const slug = event.url.split('://').pop();
      console.log({ slug });
      if (slug) {
        history.push('/' + slug);
      }
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
