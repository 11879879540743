import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const companyEndpoint = 'content/company';
export const postCompany = async (token: string, company: { title: string }) =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${companyEndpoint}${getDevelopQueryParam()}`,
    {
      headers: getHeaders(token),
      method: 'POST',
      body: JSON.stringify(company),
    },
  );

export const useCreateCompany = () => {
  return useMutation(
    ({ token, company }: { token: string; company: { title: string } }) => {
      return postCompany(token, company);
    },
  );
};
