import React, { Component } from 'react';
import { IonButton, IonContent, IonPage } from '@ionic/react';
import { FormattedMessage } from 'react-intl';

interface State {
  hasError: boolean;
  error?: any;
}

export class GlobalErrorBoundary extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: any): State {
    return {
      hasError: true,
      error,
    };
  }

  restartApp = () => {
    window.location.reload();
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <IonPage>
          <IonContent>
            <h1>
              <FormattedMessage
                id="more_film.components.error.title"
                defaultMessage="There was an unexpected error!"
              />
            </h1>
            <p>
              <FormattedMessage
                id="more_film.components.error.info"
                defaultMessage="The App has crashed and could not resolve the issue itself. The error was:"
              />
            </p>
            <blockquote>
              {this.state.error?.toString() ?? this.state.error ?? '---'}
            </blockquote>
            <IonButton onClick={this.restartApp}>
              <FormattedMessage
                id="more_film.components.error.reset"
                defaultMessage="Restart the App"
              />
            </IonButton>
          </IonContent>
        </IonPage>
      );
    }
    return this.props.children;
  }
}
