import React from 'react';
import { AlertButton, IonAlert } from '@ionic/react';
import './Alert.scss';

interface IProps {
  handleClose: (event?: any) => any;
  open: boolean;
  header: string;
  subHeader: string | undefined;
  message: string;
  buttons: string[] | AlertButton[];
}

export const Alert: React.FC<IProps> = ({
  handleClose,
  open,
  header,
  message,
  buttons,
  subHeader,
}) => {
  return (
    <IonAlert
      isOpen={open}
      onDidDismiss={(event) => handleClose(event)}
      cssClass="mod-alert"
      header={header}
      subHeader={subHeader}
      message={message}
      buttons={buttons}
    />
  );
};

export default Alert;
