import { Brainstorming } from '../../model';
import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const brainstormingEndpoint = 'content/project';
export const postBrainstorming = async (
  token: string,
  brainstorming: Brainstorming,
) =>
  wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${brainstormingEndpoint}${getDevelopQueryParam()}`,
    {
      headers: getHeaders(token),
      method: 'POST',
      body: JSON.stringify(brainstorming),
    },
  );

export const useCreateBrainstorming = () => {
  return useMutation(
    ({
      token,
      brainstorming,
    }: {
      token: string;
      brainstorming: Brainstorming;
    }) => {
      return postBrainstorming(token, brainstorming);
    },
  );
};
