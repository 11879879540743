import { getDevelopQueryParam, getHeaders } from '../getHeaders';
import { useMutation } from 'react-query';
import { wpFetch } from '../wp-fetch';

const userEndpoint = 'users/me';
export const uploadProfilePicture = async (
  token: string,
  file: Blob,
  filename: string,
) => {
  const formData = new FormData();

  formData.append('avatar', file, filename);

  return wpFetch(
    `${
      process.env.REACT_APP_API_BASE_URL
    }/${userEndpoint}${getDevelopQueryParam()}`,
    {
      headers: { Authorization: getHeaders(token).Authorization },
      method: 'POST',
      body: formData,
    },
  );
};

export const useUploadProfilePicture = () => {
  return useMutation(
    ({
      token,
      file,
      filename,
    }: {
      token: string;
      file: Blob;
      filename: string;
    }) => {
      return uploadProfilePicture(token, file, filename);
    },
  );
};
