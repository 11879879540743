import {
  CLEAR_ERROR_NOTIFICATION,
  ERROR_NOTIFICATION,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_PROFILE,
} from '../types';

const initialState = {
  success: false,
  loading: false,
  token: null,
  error: null,
};

const authReducer = function (state: any = initialState, action: any) {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
        token: action.payload,
      };
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    }

    case ERROR_NOTIFICATION: {
      return {
        success: false,
        loading: false,
        error: action.payload,
      };
    }
    case CLEAR_ERROR_NOTIFICATION: {
      return {
        ...state,
        error: null,
      };
    }
    case LOGOUT: {
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
