import { defineMessages } from 'react-intl';

export const scope = 'more_film.containers.reset_password';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Password Reset',
  },
  passwordLabel: {
    id: `${scope}.passwordLabel`,
    defaultMessage: 'New Password',
  },
  resetAction: {
    id: `${scope}.resetAction`,
    defaultMessage: 'Reset Password',
  },
  fieldRequired: {
    id: `${scope}.fieldRequired`,
    defaultMessage: 'This field is required and cannot be empty!',
  },
  fieldMinLength: {
    id: `${scope}.fieldMinLength`,
    defaultMessage: 'Your new password should be at least 12 characters long!',
  },
  successHeader: {
    id: `${scope}.successHeader`,
    defaultMessage: 'Done',
  },
  successMessage: {
    id: `${scope}.successMessage`,
    defaultMessage:
      'Your password has been updated! You will be forwarded to the login page.',
  },
  successButton: {
    id: `${scope}.successButton`,
    defaultMessage: 'Okay',
  },
  errorHeader: {
    id: `${scope}.errorHeader`,
    defaultMessage: 'Error',
  },
  errorMessage: {
    id: `${scope}.errorMessage`,
    defaultMessage:
      'Your password could not be updated! You will be forwarded to the password reset page!',
  },
  errorButton: {
    id: `${scope}.errorButton`,
    defaultMessage: 'Okay',
  },
});
