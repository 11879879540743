import React, { useCallback, useState } from 'react';
import { IonCol, IonContent, IonGrid } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { login } from '../../redux/actions/auth';
import messages from './messages';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../redux/storeHooks';
import Layout from '../../components/Layout/PageLayout';
import Alert from '../../components/Alert/Alert';
import { CLEAR_ERROR_NOTIFICATION } from '../../redux/types';
import { validate as validateEmail } from 'email-validator';
import {
  ErrorMessage,
  InputField,
  StyledButton,
  VideoContainer,
} from '../../shared/styles';
import {
  ButtonRow,
  CenterCol,
  CenterRow,
  CenterRowLarge,
  ForgotButtonRow,
  Form,
  StyledIonLink,
  TopRow,
  HorizontalRule,
  HorizontalRuleRow,
  HorizontalRuleTextCol,
  ImprintCol,
  ImprintRow,
  StyledLinkText,
} from '../../shared/login.styles';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const loginError = useAppSelector((state) => state.auth.error);
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    async (data: any) => {
      setLoading(true);
      login({ email: data.email, password: data.password })(dispatch).then(
        (res: any) => {
          if (res.status === 'success') {
            history.push('/tabs');
          }
          setLoading(false);
        },
      );
    },
    [setLoading, dispatch, history],
  );

  const email = register('email', {
    validate: (value) => {
      if (!value || value.trim().length === 0) {
        return intl.formatMessage(messages.fieldRequired);
      }
      if (!validateEmail(value)) {
        return intl.formatMessage(messages.invalidEmail);
      }
      return true;
    },
  });
  const password = register('password', {
    required: intl.formatMessage(messages.fieldRequired),
  });

  const showError = useCallback(
    (_fieldName: string) => {
      return (
        (errors as any)[_fieldName] && (
          <ErrorMessage>{errors[_fieldName]?.message}</ErrorMessage>
        )
      );
    },
    [errors],
  );

  return (
    <Layout>
      <IonContent>
        <Alert
          handleClose={() => {
            dispatch({ type: CLEAR_ERROR_NOTIFICATION });
          }}
          open={loginError !== null}
          header={intl.formatMessage(messages.loginError)}
          subHeader={undefined}
          message={loginError}
          buttons={[intl.formatMessage(messages.loginErrorAction)]}
        />
        <IonGrid>
          <CenterRow>
            <VideoContainer sizeXs="12">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <TopRow>
                  <CenterCol sizeLg="5" sizeXs="10">
                    <InputField
                      placeholder={intl.formatMessage(messages.email)}
                      type="text"
                      onIonChange={email.onChange}
                      onIonBlur={email.onBlur}
                      name={email.name}
                      ref={email.ref}
                      onKeyUp={(event) =>
                        event.key === 'Enter' && handleSubmit(onSubmit)()
                      }
                    />
                  </CenterCol>
                </TopRow>

                <CenterRow>
                  <CenterCol sizeLg="5" sizeXs="10">
                    {showError('email')}
                  </CenterCol>
                </CenterRow>
                <CenterRow>
                  <CenterCol sizeLg="5" sizeXs="10">
                    <InputField
                      placeholder={intl.formatMessage(messages.password)}
                      type="password"
                      onIonChange={password.onChange}
                      onIonBlur={password.onBlur}
                      name={password.name}
                      ref={password.ref}
                      onKeyUp={(event) =>
                        event.key === 'Enter' && handleSubmit(onSubmit)()
                      }
                    />
                  </CenterCol>
                </CenterRow>
                <CenterRow>
                  <CenterCol sizeLg="5" sizeXs="10">
                    {showError('password')}
                  </CenterCol>
                </CenterRow>
                <ButtonRow>
                  <IonCol sizeLg="5" sizeXs="10">
                    <StyledButton
                      type="submit"
                      expand="full"
                      shape="round"
                      color="primary"
                      disabled={loading}
                    >
                      {intl.formatMessage(messages.login)}
                    </StyledButton>
                  </IonCol>
                </ButtonRow>
              </Form>
              <ForgotButtonRow>
                <IonCol size="sm">
                  <StyledIonLink routerLink="/forgot-password" color="dark">
                    {intl.formatMessage(messages.forgot)}
                  </StyledIonLink>
                </IonCol>
              </ForgotButtonRow>
              <HorizontalRuleRow>
                <IonCol sizeXs="3" sizeLg="2">
                  <HorizontalRule />
                </IonCol>
                <IonCol sizeXs="3" sizeLg="1">
                  <CenterRow>
                    <HorizontalRuleTextCol sizeLg="12">
                      <StyledLinkText color="dark">
                        {intl.formatMessage(messages.or)}
                      </StyledLinkText>
                    </HorizontalRuleTextCol>
                  </CenterRow>
                </IonCol>
                <IonCol sizeXs="3" sizeLg="2">
                  <HorizontalRule />
                </IonCol>
              </HorizontalRuleRow>
              <CenterRowLarge>
                <IonCol sizeLg="5" sizeXs="10">
                  <StyledButton
                    type="submit"
                    expand="full"
                    shape="round"
                    color="primary"
                    // fill="outline"
                    disabled={loading}
                    onClick={() => {
                      history.push('/signup');
                    }}
                  >
                    {intl.formatMessage(messages.create)}
                  </StyledButton>
                </IonCol>
              </CenterRowLarge>
              <ImprintRow>
                <ImprintCol sizeLg="5" sizeXs="10">
                  <StyledLinkText color="dark">
                    <a href={process.env.REACT_APP_IMPRINT_URL}>
                      {intl.formatMessage(messages.imprintText)}
                    </a>
                    &nbsp;/&nbsp;
                    <a href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}>
                      {intl.formatMessage(messages.termsText)}
                    </a>
                  </StyledLinkText>
                </ImprintCol>
              </ImprintRow>
              <video autoPlay muted loop playsInline>
                <source src="/assets/video/intro.mp4" type="video/mp4" />
              </video>
            </VideoContainer>
          </CenterRow>
        </IonGrid>
      </IonContent>
    </Layout>
  );
};

export default Login;
