const translations = {
  // Tab Bar
  'more_film.components.bottomNav.home': 'Home',
  'more_film.components.bottomNav.newproject': 'Request',
  'more_film.components.bottomNav.projects': 'Projects',
  'more_film.components.bottomNav.contacts': 'Contacts',
  'more_film.components.bottomNav.feedback': 'Feedback',

  // Header
  'more_film.component.header.back': 'Back',

  // Header Slim
  'more_film.component.headerSlim.back': 'Back',
  'more_film.component.headerSlim.cancel': 'Cancel',

  // Login / Signup
  'more_film.containers.login.email': 'Email Address',
  'more_film.containers.login.phone': 'Telephone',
  'more_film.containers.login.password': 'Password',
  'more_film.containers.login.login': 'Login',
  'more_film.containers.login.loginError': 'Login failed!',
  'more_film.containers.login.create': 'Create New Account',
  'more_film.containers.login.or': 'or',
  'more_film.containers.login.forgotPassword': 'Forgot Password?',
  'more_film.containers.login.imprintText': 'Imprint',
  'more_film.containers.login.termsText': 'Terms & Conditions',
  'more_film.containers.login.fieldRequired': 'This field is required.',
  'more_film.containers.login.registerCompleteHeading': 'Success!',
  'more_film.containers.login.registerCompleteMessage':
    'The registration process completed successfully. You will be logged in automatically.',
  'more_film.containers.login.registerCompleteAction': 'Login',
  'more_film.containers.login.registerErrorAction': 'Close',
  'more_film.containers.login.registerErrorMessage':
    'The registration process cloud not be completed!',
  'more_film.containers.login.invalidEmail':
    'Please enter a valid email address.',
  'more_film.containers.login.invalidPhone':
    'Please enter a valid phone number.',
  'more_film.containers.login.resetPassword': 'Reset Password',
  'more_film.containers.login.resetMailSent':
    'An E-Mail with instructions to reset your password has been sent.',
  'more_film.containers.login.resetError':
    'This E-Mail might not exist in our system!',

  // Feedback
  'more_film.containers.feedback.title': 'Feedback',
  'more_film.containers.feedback.rate': 'How do you rate our company?',
  'more_film.containers.feedback.dreadful': 'Dreadful',
  'more_film.containers.feedback.poor': 'Poor',
  'more_film.containers.feedback.average': 'Average',
  'more_film.containers.feedback.fine': 'Fine',
  'more_film.containers.feedback.awesome': 'Awesome',

  'more_film.containers.feedback.praise': 'Praise',
  'more_film.containers.feedback.complaint': 'Criticism',
  'more_film.containers.feedback.suggestion': 'Suggestion',

  'more_film.containers.feedback.feedbackTypeMsg': 'Select feedback type',
  'more_film.containers.feedback.feedbackTypePlaceholder': 'Select One ...',
  'more_film.containers.feedback.feedbackOkText': 'Choose',
  'more_film.containers.feedback.feedbackCancelText': 'Cancel',
  'more_film.containers.feedback.addComment': 'Add Comment',
  'more_film.containers.feedback.commentMsg': 'Write a few sentences',
  'more_film.containers.feedback.feedbackTypeHead':
    'Can you tell us more about that?',
  'more_film.containers.feedback.sendFeedback': 'Send Feedback',
  'more_film.containers.feedback.fieldIsRequired': 'Field is required',
  'more_film.containers.feedback.alertHeader': 'Thank you for your time',
  'more_film.containers.feedback.alertMessage':
    'Unfortunately we cannot reply to any feedback. however, your opinion will help us improve our company for you and others',
  'more_film.containers.feedback.errorMessage': 'Please try again later.',
  'more_film.containers.feedback.errorHeader': 'Something went wrong!',
  // Home
  'more_film.containers.home.title': 'Home',
  'more_film.containers.home.item1': 'New Project',
  'more_film.containers.home.item2': 'Projects',
  'more_film.containers.home.item3': 'Contact',
  'more_film.containers.home.item4': 'Feedback',
  'more_film.containers.home.item5': 'Version',
  'more_film.containers.home.customers': 'Customers',

  // Contacts
  'more_film.containers.contact.title': 'Contacts',
  'more_film.containers.contact.subHeading': 'Employees',

  // Projects List
  'more_film.containers.projects.title': 'Projects',
  'more_film.containers.projects.searchBar': 'Search for a project',
  'more_film.containers.projects.noDateGiven': 'No date given',
  'more_film.containers.projects.brainstorming': 'In Brainstorming',
  'more_film.containers.projects.noOffer': 'No offer available',
  'more_film.containers.projects.offerAvailable': 'New offer available',
  'more_film.containers.projects.production-step': 'In pre production',
  'more_film.containers.projects.production': 'In production',
  'more_film.containers.projects.postproduction': 'In post production',
  'more_film.containers.projects.projectFinished': 'Done',
  'more_film.containers.projects.element': '{amount} Element',
  'more_film.containers.projects.elements': '{amount} Elements',

  // Profile
  'more_film.containers.profile.title': 'Edit Profile',
  'more_film.containers.profile.cancel': 'Cancel',
  'more_film.containers.profile.save': 'Save',
  'more_film.containers.profile.firstName': 'First Name',
  'more_film.containers.profile.lastName': 'Last Name',
  'more_film.containers.profile.address': 'Address',
  'more_film.containers.profile.country': 'Country',
  'more_film.containers.profile.email': 'E-Mail',
  'more_film.containers.profile.position': 'Position',
  'more_film.containers.profile.phone': 'Phone number',
  'more_film.containers.profile.mobile': 'Cell Phone',
  'more_film.containers.profile.website': 'Website',
  'more_film.containers.profile.company': 'Companies',
  'more_film.containers.profile.logout': 'logout',
  'more_film.containers.profile.noCompaniesAssigned':
    'Currently not assigned to any company.',
  'more_film.containers.profile.alertHeader': 'Success',
  'more_film.containers.profile.alertMessage': 'Profile Updated Successfully!',
  'more_film.containers.profile.fieldRequired': 'This field is required.',
  'more_film.containers.profile.invalidEmail':
    'Please enter a valid email address.',
  'more_film.containers.profile.invalidPhone':
    'Please enter a valid phone number.',
  'more_film.containers.profile.alertErrorHeader': 'Something went wrong!',
  'more_film.containers.profile.alertErrorMessage':
    'Your profile could not be updated! Please try again later.',
  'more_film.containers.profile.closeAction': 'Close',
  'more_film.containers.profile.secretLabel': 'Invitation Code',
  'more_film.containers.profile.shareAction': 'Share',
  'more_film.containers.profile.copyToClipboardAction': 'Copy to clipboard',
  'more_film.containers.profile.removePhoto': 'Delete profile picture',
  'more_film.containers.profile.photoRemoved':
    'Press Save to delete the picture.',
  'more_film.containers.profile.cameraUnavailable':
    'You disabled camera access. Please change that in the settings.',
  'more_film.containers.profile.libraryUnavailable':
    'You disabled photo gallery access. Please change that in the settings.',
  'more_film.containers.profile.unableToLoadImage':
    'There was an error loading the image. Please try again.',
  'more_film.containers.profile.deleteUser': 'You want to permanently delete your account?',
  'more_film.containers.profile.deleteUserInitAction': '<u>Click here</u> to delete your account.',
  'more_film.containers.profile.deleteUserHeader': 'Are you sure?',
  'more_film.containers.profile.deleteUserMessage': 'Deletion is permanent and cannot be undone! All your projects will be deleted as well.',
  'more_film.containers.profile.deleteUserAction': 'Delete my account!',
  'more_film.containers.profile.deleteUserErrorDismissAction': 'Okay',
  'more_film.containers.profile.deleteUserError': 'Something went wrong! Please contact support!',
  'more_film.containers.profile.clearAppCache': 'Clear App Cache',
  'more_film.containers.profile.appCacheCleared': 'The App Cache got cleared!',
  'more_film.containers.profile.business': 'Business management',

  // Project request
  'more_film.containers.nProject.title': 'New Project',
  'more_film.component.timeline.nProject.cardBrainstorming.head':
    'Brainstorming',
  'more_film.component.timeline.nProject.cardBrainstorming.description':
    'Describe your planned project and receive a non-binding Offer',
  'more_film.component.timeline.nProject.cardBrainstorming.button':
    'Describe project',
  'more_film.component.timeline.nProject.cardBrainstorming.footer':
    'consultation',
  'more_film.component.timeline.nProject.cardOffer.head': 'Offer',
  'more_film.component.timeline.nProject.cardOffer.description':
    'Review the non-binding offer.',
  'more_film.component.timeline.nProject.cardOffer.button': 'Review offer',
  'more_film.component.timeline.nProject.cardOffer.footer': 'Consultation',
  'more_film.component.timeline.nProject.cardOffer.acceptedDescription':
    'The offer has already been accepted.',
  'more_film.component.timeline.nProject.cardOffer.acceptedButton':
    'See original Offer',
  'more_film.component.timeline.nProject.card3.head': 'Pre-Production',
  'more_film.component.timeline.nProject.card3.description':
    'Speaker texts, location scouting, team planning, equipment planning',
  'more_film.component.timeline.nProject.card3.button': 'Details',
  'more_film.component.timeline.nProject.card4.head': 'Production',
  'more_film.component.timeline.nProject.card5.head': 'Post Production',
  'more_film.component.timeline.nProject.card6.head': 'Finished',
  'more_film.component.timeline.nProject.videoTitle': 'Information',
  'more_film.component.timeline.nProject.close': 'Close',
  'more_film.component.timeline.nProject.mediaNotFound': 'Media not found!',

  'more_film.component.timeline.nProject.card3.review':
    `We hope the result is satisfactory.
        
    Leave a Google review for Roll it Films now!
    In this way, you support us in our claim to constantly improve Roll it Films.`,
  'more_film.component.timeline.nProject.card3.link': 'Rate now',

  // Brainstorming
  'more_film.containers.nProject.brainstorming.title': 'Brainstorming',
  'more_film.containers.nProject.brainstorming.newProjectSubtitle':
    'New Project',
  'more_film.containers.nProject.brainstorming.newProjectTitle':
    'Project Information',
  'more_film.containers.nProject.brainstorming.nameLabel': 'Project Name',
  'more_film.containers.nProject.brainstorming.nameRequiredError':
    'Please give your project a fitting name.',
  'more_film.containers.nProject.brainstorming.brainstormingPlaceholder':
    'Describe your planned project and receive a non-binding offer.',
  'more_film.containers.nProject.brainstorming.brainstormingRequiredError':
    'Please describe your project.',
  'more_film.containers.nProject.brainstorming.errorHeading':
    'The project could not be created!',
  'more_film.containers.nProject.brainstorming.genericErrorMessage':
    'An unexpected error has occurred.',
  'more_film.containers.nProject.brainstorming.actionSubmit': 'Submit',

  // Offer
  'more_film.containers.projects.offer.title': 'Offer',
  'more_film.containers.projects.offer.offerStatusOpen': 'Open',
  'more_film.containers.projects.offer.offerStatusAccepted': 'Accepted',
  'more_film.containers.projects.offer.noOfferHeader': 'Offer still pending.',
  'more_film.containers.projects.offer.noOfferMessage':
    'There is no offer yet.',
  'more_film.containers.projects.offer.actionGoBack': 'Go back',
  'more_film.containers.projects.offer.offerHeader': 'Offer',
  'more_film.containers.projects.signOffer.acceptOfferAction': 'Accept offer',
  'more_film.containers.projects.signOffer.clearSignatureAction': 'Clear',
  'more_film.containers.projects.offer.viewOfferDocumentAction': 'View',
  'more_film.containers.projects.offer.openSignatureModalAction':
    'Accept Offer',
  'more_film.containers.projects.offer.closeDocumentModalAction': 'Close',
  'more_film.containers.projects.offer.showSignatureButton': 'Show signature',
  'more_film.containers.projects.offer.attachedDocumentHeader':
    'Attached Document',
  'more_film.containers.projects.signOffer.errorModalHeader':
    'Something went wrong!',
  'more_film.containers.projects.signOffer.errorModalMessage':
    'An unexpected error has occurred.<br />The offer was not accepted.',
  'more_film.containers.projects.signOffer.errorModalCloseAction': 'Close',

  'more_film.containers.projects.signOffer.signature': 'Sign here',
  'more_film.containers.projects.signOffer.date': 'Date',
  'more_film.containers.projects.signOffer.title': 'Sign Offer',
  'more_film.containers.projects.signOffer.closeAction': 'Close',

  // Project details
  'more_film.containers.projectDetail.title': 'Project',
  'more_film.containers.projectDetail.errorTitle': 'Whoops!',
  'more_film.containers.projectDetail.errorHeading': 'Something went wrong!',
  'more_film.containers.projectDetail.errorUnknown':
    'There was an unexpected error!',
  'more_film.containers.projectDetail.errorResourceNotFound':
    'The requested project could not be found or some information might be missing!',
  'more_film.containers.projectDetail.head1': 'Project Name',
  'more_film.containers.projectDetail.head2': 'Project manager',
  'more_film.containers.projectDetail.head3': 'Project completion',
  'more_film.containers.projectDetail.head4': 'Project Number',
  'more_film.containers.projectDetail.action1': 'Save', //in fotoalbum speichern
  'more_film.containers.projectDetail.action2': 'Share', //teilen
  'more_film.containers.projectDetail.action3': 'Correction', //korrectur
  'more_film.containers.projectDetail.action4': 'Praise & Criticism  ', //lob/kritik
  'more_film.containers.projectDetail.action5': 'SMS',
  'more_film.containers.projectDetail.action6': 'Email',
  'more_film.containers.projectDetail.action7': 'Copy Link',
  'more_film.containers.projectDetail.action8': 'Social Share',
  'more_film.containers.projectDetail.actionGoBack': 'Go back',
  'more_film.containers.projectDetail.detailsTab': 'Progress',
  'more_film.containers.projectDetail.mediaTab': 'Media',
  'more_film.containers.projectDetail.downloading': 'Preparing ...',
  'more_film.containers.projectDetail.savedAsset': 'File saved!',
  'more_film.containers.projectDetail.qualityHeader': 'Choose download quality:',
  'more_film.containers.projectDetail.shareAssetText':
    'New Review Link from Frame.io.',
  'more_film.containers.projectDetail.errorSharingAsset':
    'The file could not be shared!',
  'more_film.containers.projectDetail.errorSavingAsset':
    'There was an error downloading the file!',
  'more_film.containers.projectDetail.clickToEnhance': 'Click to zoom',
  'more_film.containers.projectDetail.mediaNotFound': 'Media not found!',
  'more_film.containers.projectDetail.documentsTab': 'Documents',
  'more_film.containers.projectDetail.version': "Version:",

  // Project Documents
  'more_film.containers.projects.documents.closeDocumentModalAction': 'Close',
  'more_film.containers.projects.documents.noDocumentsAvaible': 'No documents avaible',
  'more_film.containers.projects.documents.uploadDocuments': 'Upload document(s)',
  'more_film.containers.projects.documents.addDocuments': 'Add documents',
  'more_film.containers.projects.documents.dropFiles': 'Drop files here or',
  'more_film.containers.projects.documents.browseFiles': 'browse files',

  // Video Correction Player
  'more_film.containers.videoPlayer.title': 'Video Correction',
  'more_film.containers.videoPlayer.comments': 'Comments',
  'more_film.containers.videoPlayer.noComments':
    'Get started and send feedback!',
  'more_film.containers.videoPlayer.info': 'File info',
  'more_film.containers.videoPlayer.addComments': 'Add comment...',
  'more_film.containers.videoPlayer.cancelAction': 'Cancel',
  'more_film.containers.videoPlayer.sendComment': 'Send',
  'more_film.containers.videoPlayer.noFileInformation':
    'No information available!',

  // Project Specific Timeline
  'more_film.containers.projectTimeline.title': 'Project',

  'more_film.containers.create_company.startHeader': 'Welcome!',
  'more_film.containers.create_company.startMessage': "Let's start!",
  'more_film.containers.create_company.joinAction':
    'I was invited with a company code.',
  'more_film.containers.create_company.createAction':
    'I want to create my own company',
  'more_film.containers.create_company.joinToolbarHeader': 'Join',
  'more_film.containers.create_company.joinHeader': 'Welcome!',
  'more_film.containers.create_company.joinMessage':
    'Where do you want to join?',
  'more_film.containers.create_company.joinCodePlaceholder': 'Code',
  'more_film.containers.create_company.joinByCodeAction': 'Join',
  'more_film.containers.create_company.createToolbarHeader':
    'Create a new organisation',
  'more_film.containers.create_company.createHeader': 'Herzlich Willkommen!',
  'more_film.containers.create_company.createMessage':
    'An organization is the umbrella for all your projects at More. An organization is for example a company, an association or you as a private person.',
  'more_film.containers.create_company.createQuestion':
    'What do you want to call your organization?',
  'more_film.containers.create_company.createCompanyPlaceholder':
    'organization name',
  'more_film.containers.create_company.createCompanyAction': 'create',
  'more_film.containers.create_company.closeErrorAction': 'close',
  'more_film.containers.create_company.errorHeader': 'Something went wrong!',
  'more_film.containers.create_company.errorMessage':
    'Could not join the company. Please verify the code is correct or try again later.',

  'more_film.components.production_field.closeDocumentModal': 'Close',
  'more_film.components.production_field.imageGallery': 'Image gallery',
  'more_film.components.production_field.attachedDocumentHeader': 'File',
  'more_film.components.production_field.viewDocumentAction': 'View File',
  'more_film.components.production_field.contentLabel': 'Content',
  'more_film.components.production_field.filesHeader': 'Files',
  'more_film.components.production_field.errorPopoverHeader': 'Error',
  'more_film.components.production_field.errorPopoverMessage':
    'Something went wrong.',
  'more_film.components.production_field.errorPopoverConsequence':
    'The field was not updated!',
  'more_film.components.production_field.errorPopoverRetryAction': 'Try again',
  'more_film.components.production_field.errorPopoverCloseAction': 'Close',
  'more_film.components.production_field.uploadSuccessMessage':
    'File "{file}" successfully uploaded',
  'more_film.components.production_field.uploadSuccessDismissAction': 'Close',
  'more_film.components.production_field.uploadUnsuccessfulHeader':
    'Upload unsuccessful',
  'more_film.components.production_field.uploadUnsuccessfulMessage':
    'The file "{file}" could not be uploaded. Try again?',
  'more_film.components.production_field.uploadUnsuccessfulDoNotingAction':
    'No',
  'more_film.components.production_field.uploadUnsuccessfulRetryAction':
    'Try again',
  'more_film.components.production_field.noFilesAvailable':
    'There are no files available.',

  'more_film.components.upload_file.uploadAction': 'Upload',

  'more_film.containers.projects.production-step.preproduction':
    'Preproduction',
  'more_film.containers.projects.production-step.production': 'Production',
  'more_film.containers.projects.production-step.postproduction':
    'Postproduction',
  'more_film.containers.projects.production-step.noFieldsAvailableHeader':
    'Something is odd.',
  'more_film.containers.projects.production-step.noFieldsAvailableMessage':
    'There is no content here!',
  'more_film.containers.projects.production-step.goBackAction': 'Go Back',

  'more_film.containers.videoPlayer.versionSelector.cancelAction': 'Cancel',
  'more_film.containers.videoPlayer.comments.completed': 'Done!',

  // 404
  'more_film.containers.not_found.header': '😮',
  'more_film.containers.not_found.title':
    'What you are looking for could not be found.',
  'more_film.containers.not_found.link': 'Go Back',

  // Password Reset - Set Password
  'more_film.containers.reset_password.header': 'Set Password',
  'more_film.containers.reset_password.passwordLabel': 'New Password',
  'more_film.containers.reset_password.resetAction': 'Update Password',
  'more_film.containers.reset_password.fieldRequired':
    'This field cannot be empty!',
  'more_film.containers.reset_password.fieldMinLength':
    'Your new Password must be at least 12 characters long.',
  'more_film.containers.reset_password.successHeader': 'Done',
  'more_film.containers.reset_password.successMessage':
    'The password has been updated! You will now be redirected to the login.',
  'more_film.containers.reset_password.successButton': 'To the Login',
  'more_film.containers.reset_password.errorHeader': 'Error',
  'more_film.containers.reset_password.errorMessage':
    'There was an error... You will now be redirected back to the password reset page.',
  'more_film.containers.reset_password.errorButton': 'Continue',

  'more_film.containers.videoPlayer.selectColorHeader': 'Choose a color!',
  'more_film.containers.videoPlayer.errorHeader': 'Error',
  'more_film.containers.videoPlayer.errorMessage':
    'Your comment could not be saved!',
  'more_film.containers.videoPlayer.errorButton': 'Ok',
  'more_film.containers.videoPlayer.colors.white': 'White',
  'more_film.containers.videoPlayer.colors.black': 'Black',
  'more_film.containers.videoPlayer.colors.red': 'Red',
  'more_film.containers.videoPlayer.colors.green': 'Green',
  'more_film.containers.videoPlayer.colors.blue': 'Blue',
  'more_film.containers.videoPlayer.colors.cyan': 'Cyan',
  'more_film.containers.videoPlayer.colors.magenta': 'Magenta',
  'more_film.containers.videoPlayer.colors.yellow': 'Yellow',
  'more_film.containers.videoPlayer.colors.orange': 'Orange',
  'more_film.containers.videoPlayer.colors.purple': 'Purple',

  // Image Gallery
  'more_film.containers.imageGallery.title': 'Image gallery',

  'more_film.hooks.camera.promptLabelCancel': 'Cancel',
  'more_film.hooks.camera.promptLabelHeader': 'Upload a new Profil Picture',
  'more_film.hooks.camera.promptLabelPicture': 'Camera',
  'more_film.hooks.camera.promptLabelPhoto': 'Gallery',

  'more_film.components.error.title': 'There was an unexpected error!',
  'more_film.components.error.info':
    'The App has crashed and could not resolve the issue itself. The error was:',
  'more_film.components.error.reset': 'Restart the App',
  'more_film.containers.customers.title': 'Customers',
  'more_film.containers.customers.searchBar': 'Search customers',
  'more_film.containers.projectDetail.mediaNotAvailable': 'Media not available!',
};

export default translations;
