// Stores the currently-being-typechecked object for error messages.
let obj: any = null;
export class ProjectRecordProxy {
  public readonly id: number;
  public readonly title: string;
  public readonly completion: string | null;
  public readonly project_progress: ProjectRecordProgressProxy;
  public readonly frameio_media_count: number | null;
  public static Parse(d: string): ProjectRecordProxy {
    return ProjectRecordProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): ProjectRecordProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkNumber(d.id, false, field + '.id');
    checkNumber(d.frameio_media_count, true, field + '.frameio_media_count');
    if (d.frameio_media_count === undefined) {
      d.frameio_media_count = null;
    }
    checkString(d.title, false, field + '.title');
    checkString(d.completion, true, field + '.completion');
    if (d.completion === undefined) {
      d.completion = null;
    }
    d.project_progress = ProjectRecordProgressProxy.Create(
      d.project_progress,
      field + '.project_progress',
    );
    return new ProjectRecordProxy(d);
  }
  private constructor(d: any) {
    this.id = d.id;
    this.title = d.title;
    this.completion = d.completion;
    this.project_progress = d.project_progress;
    this.frameio_media_count = d.frameio_media_count;
  }
}

export class ProjectRecordProgressProxy {
  public readonly quotation_document: number | null;
  public readonly quotation_accepted: boolean;
  public readonly preproduction: PreproductionOrProductionOrPostproductionRecordProxy;
  public readonly production: PreproductionOrProductionOrPostproductionRecordProxy;
  public readonly postproduction: PreproductionOrProductionOrPostproductionRecordProxy;
  public static Parse(d: string): ProjectRecordProgressProxy {
    return ProjectRecordProgressProxy.Create(JSON.parse(d));
  }
  public static Create(
    d: any,
    field: string = 'root',
  ): ProjectRecordProgressProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkNumber(d.quotation_document, true, field + '.quotation_document');
    if (d.quotation_document === undefined) {
      d.quotation_document = null;
    }
    checkBoolean(d.quotation_accepted, false, field + '.quotation_accepted');
    d.preproduction =
      PreproductionOrProductionOrPostproductionRecordProxy.Create(
        d.preproduction,
        field + '.preproduction',
      );
    d.production = PreproductionOrProductionOrPostproductionRecordProxy.Create(
      d.production,
      field + '.production',
    );
    d.postproduction =
      PreproductionOrProductionOrPostproductionRecordProxy.Create(
        d.postproduction,
        field + '.postproduction',
      );
    return new ProjectRecordProgressProxy(d);
  }
  private constructor(d: any) {
    this.quotation_document = d.quotation_document;
    this.quotation_accepted = d.quotation_accepted;
    this.preproduction = d.preproduction;
    this.production = d.production;
    this.postproduction = d.postproduction;
  }
}

export class PreproductionOrProductionOrPostproductionRecordProxy {
  public readonly completed: boolean;
  public static Parse(
    d: string,
  ): PreproductionOrProductionOrPostproductionRecordProxy {
    return PreproductionOrProductionOrPostproductionRecordProxy.Create(
      JSON.parse(d),
    );
  }
  public static Create(
    d: any,
    field: string = 'root',
  ): PreproductionOrProductionOrPostproductionRecordProxy {
    if (!field) {
      obj = d;
      field = 'root';
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof d !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkBoolean(d.completed, false, field + '.completed');
    return new PreproductionOrProductionOrPostproductionRecordProxy(d);
  }
  private constructor(d: any) {
    this.completed = d.completed;
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, 'non-nullable object', false);
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable);
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, 'object', nullable);
}
function checkNumber(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'number' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'number', nullable);
  }
}
function checkBoolean(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'boolean' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'boolean', nullable);
  }
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (
    typeof d !== 'string' &&
    (!nullable || (nullable && d !== null && d !== undefined))
  ) {
    errorHelper(field, d, 'string', nullable);
  }
}
function errorHelper(
  field: string,
  d: any,
  type: string,
  nullable: boolean,
): never {
  if (nullable) {
    type += ', null, or undefined';
  }
  throw new TypeError(
    'Expected ' +
      type +
      ' at ' +
      field +
      ' but found:\n' +
      JSON.stringify(d) +
      '\n\nFull object:\n' +
      JSON.stringify(obj),
  );
}
