import thunk from 'redux-thunk';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';

import RootReducer from './reducers/RootReducer';
import { persistStore } from 'redux-persist';

const initialState = {};
const middlewares = [thunk];
let devtools = (x?: any) => x;

if (
  process.env.NODE_ENV !== 'production' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = (window as any).__REDUX_DEVTOOLS_EXTENSION__();
}

const store = createStore(
  RootReducer,
  initialState,
  compose(applyMiddleware(...middlewares)),
);

const exports = { store, persistor: persistStore(store) };

export default exports;
