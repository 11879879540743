// Stores the currently-being-typechecked object for error messages.
import { FrameIoMediaCount } from './frame-io-count';

let obj: any = null;
export class FrameIoMediaCountProxy implements FrameIoMediaCount {
  public readonly count: number;
  public readonly id: number;
  public static Parse(d: string): FrameIoMediaCountProxy {
    return FrameIoMediaCountProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): FrameIoMediaCountProxy {
    if (!field) {
      obj = d;
      field = "root";
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof(d) !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkNumber(d.count, false, field + ".count");
    checkNumber(d.id, false, field + ".id");
    return new FrameIoMediaCountProxy(d);
  }
  private constructor(d: any) {
    this.count = d.count;
    this.id = d.id;
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, "non-nullable object", false);
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable);
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable);
}
function checkNumber(d: any, nullable: boolean, field: string): void {
  if (typeof(d) !== 'number' && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "number", nullable);
  }
}
function errorHelper(field: string, d: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ", null, or undefined";
  }
  throw new TypeError('Expected ' + type + " at " + field + " but found:\n" + JSON.stringify(d) + "\n\nFull object:\n" + JSON.stringify(obj));
}
