import styled, { css } from 'styled-components';
import { IonCol, IonRouterLink, IonRow, IonText } from '@ionic/react';

const linkStyles = css`
  font-family: BaiJamjureeBold, sans-serif;

  a {
    text-decoration: none;
  }
`;

export const StyledIonLink = styled(IonRouterLink)`
  ${linkStyles}
`;

export const StyledLinkText = styled(IonText)`
  ${linkStyles}
`;

const centerRowStyles = css`
  justify-content: center;
`;

export const CenterRow = styled(IonRow)`
  ${centerRowStyles}
`;

export const CenterRowLarge = styled(IonRow)`
  ${centerRowStyles};
  width: 100%;
`;

export const ImprintRow = styled(IonRow)`
  ${centerRowStyles};
  width: 100%;
  align-items: center;
`;

export const ImprintCol = styled(IonCol)`
  text-align: center;
`;

export const TopRow = styled(IonRow)`
  ${centerRowStyles};
  margin-top: 5%;
`;

const ButtonRowStyles = css`
  ${centerRowStyles};
  margin-top: 5px;
`;

export const ButtonRow = styled(IonRow)`
  ${ButtonRowStyles}
`;

export const ForgotButtonRow = styled(IonRow)`
  ${ButtonRowStyles};
  width: 100%;
`;

export const HorizontalRuleRow = styled(IonRow)`
  ${centerRowStyles};
  align-items: center;
  margin-top: 20%;
  width: 100%;
`;

export const HorizontalRuleTextCol = styled(IonCol)`
  text-transform: uppercase;
  text-align: center;
`;

export const HorizontalRule = styled.hr`
  border-top: 1px solid #000000;
`;

export const CenterCol = styled(IonCol)`
  display: flex !important;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
`;
