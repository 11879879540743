import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonRouterLink,
} from '@ionic/react';
import PageLayout from '../../components/Layout/PageLayout';
import { useIntl } from 'react-intl';
import messages from './messages';
import Header from '../../components/Header/Header';

const NotFound = () => {
  const intl = useIntl();

  return (
    <PageLayout>
      <Header name={intl.formatMessage(messages.header)} backButton={false} />
      <IonContent fullscreen={true}>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{intl.formatMessage(messages.title)}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonRouterLink href="/">
              {intl.formatMessage(messages.link)}
            </IonRouterLink>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </PageLayout>
  );
};

export default NotFound;
