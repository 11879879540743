/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'more_film.containers.login';

export default defineMessages({
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Login',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'E-Mail Address',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Phone: +49 0123456789',
  },
  invalidPhone: {
    id: `${scope}.invalidPhone`,
    defaultMessage: 'Please enter a valid phone number',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Create New Account',
  },
  or: {
    id: `${scope}.or`,
    defaultMessage: 'or',
  },
  forgot: {
    id: `${scope}.forgotPassword`,
    defaultMessage: 'Forgot Password?',
  },
  imprintText: {
    id: `${scope}.imprintText`,
    defaultMessage: 'Imprint',
  },
  termsText: {
    id: `${scope}.termsText`,
    defaultMessage: 'Terms and Conditions',
  },
  fieldRequired: {
    id: `${scope}.fieldRequired`,
    defaultMessage: 'This field is required.',
  },
  invalidEmail: {
    id: `${scope}.invalidEmail`,
    defaultMessage: 'Please enter a valid email address.',
  },
  registerCompleteHeading: {
    id: `${scope}.registerCompleteHeading`,
    defaultMessage: 'Success!',
  },
  registerCompleteMessage: {
    id: `${scope}.registerCompleteMessage`,
    defaultMessage:
      'The registration process completed successfully. You will be logged in automatically.',
  },
  registerCompleteAction: {
    id: `${scope}.registerErrorAction`,
    defaultMessage: 'Login',
  },
  registerErrorAction: {
    id: `${scope}.registerErrorAction`,
    defaultMessage: 'Close',
  },
  registerErrorMessage: {
    id: `${scope}.registerErrorMessage`,
    defaultMessage: 'The registration process cloud not be completed!',
  },
});
