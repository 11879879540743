import { defineMessages } from 'react-intl';

export const scope = 'more_film.containers.not_found';

export default defineMessages({
  header: {
    id: `${scope}.header`,
  },
  title: {
    id: `${scope}.title`,
  },
  link: {
    id: `${scope}.link`,
  },
});
