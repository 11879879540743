import {
  ADD_COMMENT,
  DELETE_COMMENT,
  SET_COMMENTS_LIST,
  UPDATE_COMMENT,
} from '../types';

const initialState = {
  comments: [],
};

const commentsReducer = function (state: any = initialState, action: any) {
  switch (action.type) {
    case SET_COMMENTS_LIST: {
      return {
        ...state,
        comments: action.payload,
      };
    }
    case ADD_COMMENT: {
      let _comments = [action.payload, ...state.comments];
      return {
        ...state,
        comments: _comments,
      };
    }
    case DELETE_COMMENT: {
      let _comments = state.comments.filter(
        (item: any) => item.id !== action.payload.id,
      );
      return {
        ...state,
        comments: _comments,
      };
    }
    case UPDATE_COMMENT: {
      let _comments = [...state.comments];
      let index = state.comments.findIndex(
        (item: any) => item.id === action.payload.id,
      );
      if (index > -1) {
        _comments[index] = { ...action.payload };
      }

      console.log({ _comments, index });
      return {
        ...state,
        comments: _comments,
      };
    }

    default: {
      return state;
    }
  }
};

export default commentsReducer;
