import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from './authReducer';
import language from './languageReducer';
import feedback from './feedbackReducer';
import projects from './projectsReducer';
import comments from './commentsReducer';

const rootReducer = persistReducer(
  {
    key: 'root', // key is required
    storage, // storage is now required
    whitelist: ['auth'],
  },

  combineReducers({
    auth,
    language,
    feedback,
    projects,
    comments,
  }),
);

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
