import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonLoading,
  useIonAlert,
} from '@ionic/react';
import React, { useCallback, useState } from 'react';
import PageLayout from '../../components/Layout/PageLayout';
import Header from '../../components/Header/Header';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { eye, eyeOff } from 'ionicons/icons';
import { useSetPassword } from '../../api';
import { useIntl } from 'react-intl';
import messages from './messages';
import {
  ButtonCol,
  ButtonRow,
  ErrorCol,
  ErrorMessage,
  ErrorRow,
  Flex,
  IconButton,
  InfoCol,
  InputRow,
  PasswordField,
  ResetButton,
} from './PasswordReset.styles';

const PasswordReset = () => {
  const { key } = useParams<{ key: string }>();
  const mutation = useSetPassword();
  const history = useHistory();

  const [present] = useIonAlert();
  const intl = useIntl();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const password = register('password', {
    required: intl.formatMessage(messages.fieldRequired),
    minLength: {
      value: 12,
      message: intl.formatMessage(messages.fieldMinLength),
    },
  });

  const showError = (_fieldName: string) => {
    return (
      (errors as any)[_fieldName] && (
        <ErrorMessage>{errors[_fieldName]?.message}</ErrorMessage>
      )
    );
  };

  const onSubmit = useCallback(
    (data: any) => {
      if (!data?.password) {
        return;
      }
      setLoading(true);
      mutation.mutate(
        {
          resetToken: key,
          password: data.password,
        },
        {
          onSettled: async (req, err) => {
            setLoading(false);
            reset();
            if (req?.ok && !err) {
              const requestData = await req.json();
              if (requestData.hasOwnProperty('ok') && requestData.ok === true) {
                present({
                  header: intl.formatMessage(messages.successHeader),
                  message: intl.formatMessage(messages.successMessage),
                  buttons: [
                    {
                      text: intl.formatMessage(messages.successButton),
                      handler: () => {
                        history.push('/login');
                      },
                    },
                  ],
                });
                return;
              }
            }
            present({
              header: intl.formatMessage(messages.errorHeader),
              message: intl.formatMessage(messages.errorMessage),
              buttons: [
                {
                  text: intl.formatMessage(messages.errorButton),
                  handler: () => {
                    history.push('/forgot-password');
                  },
                },
              ],
            });
          },
        },
      );
    },
    [key, present, setLoading, mutation, reset, intl, history],
  );

  return (
    <PageLayout>
      <Header name={intl.formatMessage(messages.header)} />
      <IonContent>
        <IonLoading isOpen={loading} />
        <IonCard>
          <IonGrid>
            <IonCol sizeXs="12" className="ion-no-padding">
              <div className="ion-padding-top ion-padding-bottom">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <InputRow>
                    <InfoCol sizeLg="5" sizeXs="12">
                      <IonLabel>
                        {intl.formatMessage(messages.passwordLabel)}
                      </IonLabel>
                    </InfoCol>
                  </InputRow>
                  <InputRow>
                    <IonCol sizeLg="5" sizeXs="12">
                      <Flex>
                        <PasswordField
                          type={showPassword ? 'text' : 'password'}
                          id="new-password"
                          autocomplete="new-password"
                          onIonChange={password.onChange}
                          onIonBlur={password.onBlur}
                          name={password.name}
                          ref={password.ref}
                        />
                        <IconButton
                          mode="md"
                          disabled={loading}
                          onClick={() => {
                            setShowPassword((prev) => !prev);
                          }}
                        >
                          <IonIcon
                            icon={showPassword ? eyeOff : eye}
                            slot="icon-only"
                          />
                        </IconButton>
                      </Flex>
                    </IonCol>
                  </InputRow>

                  <ErrorRow>
                    <ErrorCol sizeLg="5" sizeXs="12">
                      {showError('password')}
                    </ErrorCol>
                  </ErrorRow>

                  <ButtonRow>
                    <ButtonCol sizeLg="5" sizeXs="12">
                      <ResetButton
                        mode="md"
                        type="submit"
                        expand="full"
                        shape="round"
                        color="primary"
                      >
                        {intl.formatMessage(messages.resetAction)}
                      </ResetButton>
                    </ButtonCol>
                  </ButtonRow>
                </form>
              </div>
            </IonCol>
          </IonGrid>
        </IonCard>
      </IonContent>
    </PageLayout>
  );
};

export default PasswordReset;
